/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const listGoalReports = /* GraphQL */ `query ListGoalReports(
  $groupBy: OnoReportQueriesGroupBy!
  $organizationID: ID!
  $organizationGroup: String!
  $adminGroup: String!
  $searchPeriod: SearchPeriod
) {
  listGoalReports(
    groupBy: $groupBy
    organizationID: $organizationID
    organizationGroup: $organizationGroup
    adminGroup: $adminGroup
    searchPeriod: $searchPeriod
  ) {
    graphDataLabels
    graphData {
      id
      title
      values
      __typename
    }
    tableData {
      id
      title
      allCount
      notUpdatedCount
      offTrackCount
      progressingCount
      onTrackCount
      imperfectCount
      perfectCount
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGoalReportsQueryVariables,
  APITypes.ListGoalReportsQuery
>;
export const AnalysisReportQueries = /* GraphQL */ `query AnalysisReportQueries(
  $type: AnalysisReportType!
  $adminGroup: String!
  $start: AWSDate!
  $end: AWSDate!
) {
  AnalysisReportQueries(
    type: $type
    adminGroup: $adminGroup
    start: $start
    end: $end
  ) {
    agendaTableData {
      type
      title
      completedCount
      wholeCount
      __typename
    }
    onoTableData {
      id
      name
      settingRate
      memberItems {
        id
        name
        setting
        wholeMeetings
        participatedMeetings
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AnalysisReportQueriesQueryVariables,
  APITypes.AnalysisReportQueriesQuery
>;
export const AnalysisOneOnOne = /* GraphQL */ `query AnalysisOneOnOne(
  $organizationGroup: String!
  $start: AWSDate!
  $end: AWSDate!
) {
  AnalysisOneOnOne(
    organizationGroup: $organizationGroup
    start: $start
    end: $end
  ) {
    managerID
    managerName
    settingRate
    memberOneOnOneAnalysisDataArray {
      memberID
      memberName
      oneOnOneCount
      participatedOneOnOneCount
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AnalysisOneOnOneQueryVariables,
  APITypes.AnalysisOneOnOneQuery
>;
export const AnalysisAgenda = /* GraphQL */ `query AnalysisAgenda(
  $organizationGroup: String!
  $start: AWSDate!
  $end: AWSDate!
) {
  AnalysisAgenda(
    organizationGroup: $organizationGroup
    start: $start
    end: $end
  ) {
    type
    title
    displayCount
    usedCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AnalysisAgendaQueryVariables,
  APITypes.AnalysisAgendaQuery
>;
export const DownloadOneOnOneHistory = /* GraphQL */ `query DownloadOneOnOneHistory(
  $type: OneOnOneHistoryType!
  $currentUserID: String!
  $organizationGroup: String!
) {
  DownloadOneOnOneHistory(
    type: $type
    currentUserID: $currentUserID
    organizationGroup: $organizationGroup
  ) {
    id
    managerName
    user1Name
    user2Name
    startDateTime
    status
    agendaContents {
      agenda
      comments
      __typename
    }
    nextActions {
      nextAction
      targetDate
      manager
      __typename
    }
    shareMemos {
      memo
      writer
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DownloadOneOnOneHistoryQueryVariables,
  APITypes.DownloadOneOnOneHistoryQuery
>;
export const getTeam = /* GraphQL */ `query GetTeam($id: ID!) {
  getTeam(id: $id) {
    id
    name
    description
    createdAt
    updatedAt
    originalPhotoUrl
    thumbnailPhotoUrl
    coverImageUrl
    organizationGroup
    adminGroup
    delete
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTeamQueryVariables, APITypes.GetTeamQuery>;
export const listTeams = /* GraphQL */ `query ListTeams(
  $filter: ModelTeamFilterInput
  $limit: Int
  $nextToken: String
) {
  listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      createdAt
      updatedAt
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationGroup
      adminGroup
      delete
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListTeamsQueryVariables, APITypes.ListTeamsQuery>;
export const listTeamByAdminGroup = /* GraphQL */ `query ListTeamByAdminGroup(
  $adminGroup: String
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTeamFilterInput
  $limit: Int
  $nextToken: String
) {
  listTeamByAdminGroup(
    adminGroup: $adminGroup
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      description
      createdAt
      updatedAt
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationGroup
      adminGroup
      delete
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTeamByAdminGroupQueryVariables,
  APITypes.ListTeamByAdminGroupQuery
>;
export const getMemberManager = /* GraphQL */ `query GetMemberManager($id: ID!) {
  getMemberManager(id: $id) {
    id
    managerID
    memberID
    organizationGroup
    adminGroup
    createdAt
    updatedAt
    delete
    owner
    manager {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    member {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMemberManagerQueryVariables,
  APITypes.GetMemberManagerQuery
>;
export const listMemberManagers = /* GraphQL */ `query ListMemberManagers(
  $filter: ModelMemberManagerFilterInput
  $limit: Int
  $nextToken: String
) {
  listMemberManagers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      managerID
      memberID
      organizationGroup
      adminGroup
      createdAt
      updatedAt
      delete
      owner
      manager {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      member {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMemberManagersQueryVariables,
  APITypes.ListMemberManagersQuery
>;
export const listMemberByUser = /* GraphQL */ `query ListMemberByUser(
  $memberID: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMemberManagerFilterInput
  $limit: Int
  $nextToken: String
) {
  listMemberByUser(
    memberID: $memberID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      managerID
      memberID
      organizationGroup
      adminGroup
      createdAt
      updatedAt
      delete
      owner
      manager {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      member {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMemberByUserQueryVariables,
  APITypes.ListMemberByUserQuery
>;
export const listMemberByManager = /* GraphQL */ `query ListMemberByManager(
  $managerID: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMemberManagerFilterInput
  $limit: Int
  $nextToken: String
) {
  listMemberByManager(
    managerID: $managerID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      managerID
      memberID
      organizationGroup
      adminGroup
      createdAt
      updatedAt
      delete
      owner
      manager {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      member {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMemberByManagerQueryVariables,
  APITypes.ListMemberByManagerQuery
>;
export const listMemberManagerByAdminGroup = /* GraphQL */ `query ListMemberManagerByAdminGroup(
  $adminGroup: String
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMemberManagerFilterInput
  $limit: Int
  $nextToken: String
) {
  listMemberManagerByAdminGroup(
    adminGroup: $adminGroup
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      managerID
      memberID
      organizationGroup
      adminGroup
      createdAt
      updatedAt
      delete
      owner
      manager {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      member {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMemberManagerByAdminGroupQueryVariables,
  APITypes.ListMemberManagerByAdminGroupQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    firstName
    lastName
    account
    owner
    originalPhotoUrl
    thumbnailPhotoUrl
    coverImageUrl
    organizationID
    status
    admin
    sub
    socialType
    drivingScore
    analyticalScore
    amiableScore
    expressiveScore
    createdAt
    updatedAt
    organizationGroup
    adminGroup
    email
    teamID
    managers
    googleRefreshToken
    googleAccountEmail
    about
    delete
    deleteStatus
    employeeId
    teamCode
    readingFirstName
    readingLastName
    birthDay
    gender
    address
    joinedCompanyDay
    retirementDay
    jobType
    phoneNumber
    enrollmentStatus
    recruitmentClassification
    department
    division
    section
    position
    gradeID
    allowance
    bonus
    gradeMatrixID
    myMembers {
      items {
        id
        managerID
        memberID
        organizationGroup
        adminGroup
        createdAt
        updatedAt
        delete
        owner
        manager {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        member {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    myManagers {
      items {
        id
        managerID
        memberID
        organizationGroup
        adminGroup
        createdAt
        updatedAt
        delete
        owner
        manager {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        member {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    gradeMatrix {
      gradeMatrixID
      title
      description
      organizationGroup
      createdAt
      updatedAt
      memberList {
        items {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      competencyAreaList {
        items {
          competencyAreaID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      gradeList {
        items {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      competencyItemList {
        items {
          competencyItemID
          item
          gradeID
          competencyAreaID
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    grade {
      gradeID
      title
      description
      gradeMatrixID
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const listUserByOrganizationID = /* GraphQL */ `query ListUserByOrganizationID(
  $organizationID: ID
  $status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserByOrganizationID(
    organizationID: $organizationID
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserByOrganizationIDQueryVariables,
  APITypes.ListUserByOrganizationIDQuery
>;
export const listUserByOrganization = /* GraphQL */ `query ListUserByOrganization(
  $organizationID: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserByOrganization(
    organizationID: $organizationID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserByOrganizationQueryVariables,
  APITypes.ListUserByOrganizationQuery
>;
export const listUserByAdminGroup = /* GraphQL */ `query ListUserByAdminGroup(
  $adminGroup: String
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserByAdminGroup(
    adminGroup: $adminGroup
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserByAdminGroupQueryVariables,
  APITypes.ListUserByAdminGroupQuery
>;
export const listUserByEmail = /* GraphQL */ `query ListUserByEmail(
  $email: String
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserByEmail(
    email: $email
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserByEmailQueryVariables,
  APITypes.ListUserByEmailQuery
>;
export const listUserByTeamID = /* GraphQL */ `query ListUserByTeamID(
  $teamID: String
  $adminGroup: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserByTeamID(
    teamID: $teamID
    adminGroup: $adminGroup
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserByTeamIDQueryVariables,
  APITypes.ListUserByTeamIDQuery
>;
export const listUserByGradeMatrixID = /* GraphQL */ `query ListUserByGradeMatrixID(
  $gradeMatrixID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserByGradeMatrixID(
    gradeMatrixID: $gradeMatrixID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserByGradeMatrixIDQueryVariables,
  APITypes.ListUserByGradeMatrixIDQuery
>;
export const getHistory = /* GraphQL */ `query GetHistory($id: ID!) {
  getHistory(id: $id) {
    id
    user
    value
    subValue
    start
    end
    type
    organizationGroup
    adminGroup
    createdAt
    updatedAt
    delete
    groupsCanAccess
    owners
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetHistoryQueryVariables,
  APITypes.GetHistoryQuery
>;
export const listHistorys = /* GraphQL */ `query ListHistorys(
  $filter: ModelHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listHistorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      user
      value
      subValue
      start
      end
      type
      organizationGroup
      adminGroup
      createdAt
      updatedAt
      delete
      groupsCanAccess
      owners
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListHistorysQueryVariables,
  APITypes.ListHistorysQuery
>;
export const listHistoryByGroup = /* GraphQL */ `query ListHistoryByGroup(
  $adminGroup: String
  $type: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listHistoryByGroup(
    adminGroup: $adminGroup
    type: $type
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user
      value
      subValue
      start
      end
      type
      organizationGroup
      adminGroup
      createdAt
      updatedAt
      delete
      groupsCanAccess
      owners
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListHistoryByGroupQueryVariables,
  APITypes.ListHistoryByGroupQuery
>;
export const listHistoryByUser = /* GraphQL */ `query ListHistoryByUser(
  $user: ID
  $type: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listHistoryByUser(
    user: $user
    type: $type
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user
      value
      subValue
      start
      end
      type
      organizationGroup
      adminGroup
      createdAt
      updatedAt
      delete
      groupsCanAccess
      owners
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListHistoryByUserQueryVariables,
  APITypes.ListHistoryByUserQuery
>;
export const getSetting = /* GraphQL */ `query GetSetting($id: ID!) {
  getSetting(id: $id) {
    id
    notification {
      disableAll
      email {
        enable
        reportLike
        reportComment
        onoRemind
        onoLookBack
        goalUpdate
        goalActivity
        goalRemind
        __typename
      }
      __typename
    }
    calendar {
      gmailAddress
      syncLimit
      syncInterval
      syncToken
      __typename
    }
    organizationGroup
    adminGroup
    delete
    createdAt
    updatedAt
    groupsCanAccess
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSettingQueryVariables,
  APITypes.GetSettingQuery
>;
export const listSettings = /* GraphQL */ `query ListSettings(
  $filter: ModelSettingFilterInput
  $limit: Int
  $nextToken: String
) {
  listSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      notification {
        disableAll
        email {
          enable
          reportLike
          reportComment
          onoRemind
          onoLookBack
          goalUpdate
          goalActivity
          goalRemind
          __typename
        }
        __typename
      }
      calendar {
        gmailAddress
        syncLimit
        syncInterval
        syncToken
        __typename
      }
      organizationGroup
      adminGroup
      delete
      createdAt
      updatedAt
      groupsCanAccess
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSettingsQueryVariables,
  APITypes.ListSettingsQuery
>;
export const listSettingByAdminGroup = /* GraphQL */ `query ListSettingByAdminGroup(
  $adminGroup: String
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSettingFilterInput
  $limit: Int
  $nextToken: String
) {
  listSettingByAdminGroup(
    adminGroup: $adminGroup
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      notification {
        disableAll
        email {
          enable
          reportLike
          reportComment
          onoRemind
          onoLookBack
          goalUpdate
          goalActivity
          goalRemind
          __typename
        }
        __typename
      }
      calendar {
        gmailAddress
        syncLimit
        syncInterval
        syncToken
        __typename
      }
      organizationGroup
      adminGroup
      delete
      createdAt
      updatedAt
      groupsCanAccess
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSettingByAdminGroupQueryVariables,
  APITypes.ListSettingByAdminGroupQuery
>;
export const getTask = /* GraphQL */ `query GetTask($taskID: ID!) {
  getTask(taskID: $taskID) {
    taskID
    userID
    content
    title
    taskType
    complete
    createdAt
    updatedAt
    dueDate
    taskLink
    organizationGroup
    reviewSheetID
    goalID
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTaskQueryVariables, APITypes.GetTaskQuery>;
export const listTasks = /* GraphQL */ `query ListTasks(
  $taskID: ID
  $filter: ModelTaskFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listTasks(
    taskID: $taskID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      taskID
      userID
      content
      title
      taskType
      complete
      createdAt
      updatedAt
      dueDate
      taskLink
      organizationGroup
      reviewSheetID
      goalID
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListTasksQueryVariables, APITypes.ListTasksQuery>;
export const listTaskByUser = /* GraphQL */ `query ListTaskByUser(
  $organizationGroup: String
  $userID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTaskFilterInput
  $limit: Int
  $nextToken: String
) {
  listTaskByUser(
    organizationGroup: $organizationGroup
    userID: $userID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      taskID
      userID
      content
      title
      taskType
      complete
      createdAt
      updatedAt
      dueDate
      taskLink
      organizationGroup
      reviewSheetID
      goalID
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTaskByUserQueryVariables,
  APITypes.ListTaskByUserQuery
>;
export const listReviewerTask = /* GraphQL */ `query ListReviewerTask(
  $reviewSheetID: ID
  $userID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTaskFilterInput
  $limit: Int
  $nextToken: String
) {
  listReviewerTask(
    reviewSheetID: $reviewSheetID
    userID: $userID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      taskID
      userID
      content
      title
      taskType
      complete
      createdAt
      updatedAt
      dueDate
      taskLink
      organizationGroup
      reviewSheetID
      goalID
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReviewerTaskQueryVariables,
  APITypes.ListReviewerTaskQuery
>;
export const listReviewTask = /* GraphQL */ `query ListReviewTask(
  $reviewSheetID: ID
  $taskType: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTaskFilterInput
  $limit: Int
  $nextToken: String
) {
  listReviewTask(
    reviewSheetID: $reviewSheetID
    taskType: $taskType
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      taskID
      userID
      content
      title
      taskType
      complete
      createdAt
      updatedAt
      dueDate
      taskLink
      organizationGroup
      reviewSheetID
      goalID
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReviewTaskQueryVariables,
  APITypes.ListReviewTaskQuery
>;
export const listTaskByGoal = /* GraphQL */ `query ListTaskByGoal(
  $goalID: ID
  $userID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTaskFilterInput
  $limit: Int
  $nextToken: String
) {
  listTaskByGoal(
    goalID: $goalID
    userID: $userID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      taskID
      userID
      content
      title
      taskType
      complete
      createdAt
      updatedAt
      dueDate
      taskLink
      organizationGroup
      reviewSheetID
      goalID
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTaskByGoalQueryVariables,
  APITypes.ListTaskByGoalQuery
>;
export const getOrganization = /* GraphQL */ `query GetOrganization($id: ID!) {
  getOrganization(id: $id) {
    id
    name
    domain
    webhookURL
    mailingList
    adminGroupName
    originalPhotoUrl
    thumbnailPhotoUrl
    coverImageUrl
    groupName
    numberOfUsers
    agreementUpdateDate
    assessmentFromDate
    assessmentToDate
    createdAt
    updatedAt
    organizationGroup
    adminGroup
    delete
    totalAudioDuration
    status
    startDate
    endDate
    microsoftAppId
    microsoftTenant
    microsoftClientSecret
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationQueryVariables,
  APITypes.GetOrganizationQuery
>;
export const listOrganizations = /* GraphQL */ `query ListOrganizations(
  $filter: ModelOrganizationFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      domain
      webhookURL
      mailingList
      adminGroupName
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      groupName
      numberOfUsers
      agreementUpdateDate
      assessmentFromDate
      assessmentToDate
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      delete
      totalAudioDuration
      status
      startDate
      endDate
      microsoftAppId
      microsoftTenant
      microsoftClientSecret
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrganizationsQueryVariables,
  APITypes.ListOrganizationsQuery
>;
export const listOrganizationsByName = /* GraphQL */ `query ListOrganizationsByName(
  $name: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOrganizationFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrganizationsByName(
    name: $name
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      domain
      webhookURL
      mailingList
      adminGroupName
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      groupName
      numberOfUsers
      agreementUpdateDate
      assessmentFromDate
      assessmentToDate
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      delete
      totalAudioDuration
      status
      startDate
      endDate
      microsoftAppId
      microsoftTenant
      microsoftClientSecret
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrganizationsByNameQueryVariables,
  APITypes.ListOrganizationsByNameQuery
>;
export const getGoal3 = /* GraphQL */ `query GetGoal3($id: ID!) {
  getGoal3(id: $id) {
    id
    organization
    teamID
    value
    detail
    progressType
    startDate
    endDate
    owners
    type
    visibility
    readers
    parentGoalID
    priority
    achievement
    progress
    totalValue
    start
    target
    organizationGroup
    adminGroup
    createdAt
    updatedAt
    delete
    archiveStatus
    goalProgressList {
      items {
        goalProgressID
        organizationGroup
        goalID
        progress
        progressDetail
        value
        creatorID
        createdAt
        deleteStatus
        updatedAt
        comments {
          items {
            goalProgressCommentID
            organizationGroup
            commenterID
            commentContent
            goalProgressID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetGoal3QueryVariables, APITypes.GetGoal3Query>;
export const listGoal3s = /* GraphQL */ `query ListGoal3s(
  $filter: ModelGoal3FilterInput
  $limit: Int
  $nextToken: String
) {
  listGoal3s(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      organization
      teamID
      value
      detail
      progressType
      startDate
      endDate
      owners
      type
      visibility
      readers
      parentGoalID
      priority
      achievement
      progress
      totalValue
      start
      target
      organizationGroup
      adminGroup
      createdAt
      updatedAt
      delete
      archiveStatus
      goalProgressList {
        items {
          goalProgressID
          organizationGroup
          goalID
          progress
          progressDetail
          value
          creatorID
          createdAt
          deleteStatus
          updatedAt
          comments {
            nextToken
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGoal3sQueryVariables,
  APITypes.ListGoal3sQuery
>;
export const listGoal3ByAdminGroup = /* GraphQL */ `query ListGoal3ByAdminGroup(
  $adminGroup: String
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelGoal3FilterInput
  $limit: Int
  $nextToken: String
) {
  listGoal3ByAdminGroup(
    adminGroup: $adminGroup
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      organization
      teamID
      value
      detail
      progressType
      startDate
      endDate
      owners
      type
      visibility
      readers
      parentGoalID
      priority
      achievement
      progress
      totalValue
      start
      target
      organizationGroup
      adminGroup
      createdAt
      updatedAt
      delete
      archiveStatus
      goalProgressList {
        items {
          goalProgressID
          organizationGroup
          goalID
          progress
          progressDetail
          value
          creatorID
          createdAt
          deleteStatus
          updatedAt
          comments {
            nextToken
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGoal3ByAdminGroupQueryVariables,
  APITypes.ListGoal3ByAdminGroupQuery
>;
export const getGoal = /* GraphQL */ `query GetGoal($goalID: ID!) {
  getGoal(goalID: $goalID) {
    goalID
    organizationGroup
    parentGoalID
    departmentID
    directorIDs
    readerIDs
    goalClassification
    goalTitle
    goalDetail
    measuringType
    startDate
    targetDate
    goalType
    publicScope
    archiveStatus
    startValue
    targetValue
    createdAt
    updatedAt
    deleteStatus
    goalWeightPoint
    goalProgressList {
      items {
        goalProgressID
        organizationGroup
        goalID
        progress
        progressDetail
        value
        creatorID
        createdAt
        deleteStatus
        updatedAt
        comments {
          items {
            goalProgressCommentID
            organizationGroup
            commenterID
            commentContent
            goalProgressID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetGoalQueryVariables, APITypes.GetGoalQuery>;
export const listGoals = /* GraphQL */ `query ListGoals(
  $goalID: ID
  $filter: ModelGoalFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listGoals(
    goalID: $goalID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      goalID
      organizationGroup
      parentGoalID
      departmentID
      directorIDs
      readerIDs
      goalClassification
      goalTitle
      goalDetail
      measuringType
      startDate
      targetDate
      goalType
      publicScope
      archiveStatus
      startValue
      targetValue
      createdAt
      updatedAt
      deleteStatus
      goalWeightPoint
      goalProgressList {
        items {
          goalProgressID
          organizationGroup
          goalID
          progress
          progressDetail
          value
          creatorID
          createdAt
          deleteStatus
          updatedAt
          comments {
            nextToken
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListGoalsQueryVariables, APITypes.ListGoalsQuery>;
export const listGoalByOrganizationGroup = /* GraphQL */ `query ListGoalByOrganizationGroup(
  $organizationGroup: String
  $archiveStatusDeleteStatus: ModelGoalListGoalByOrganizationGroupCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelGoalFilterInput
  $limit: Int
  $nextToken: String
) {
  listGoalByOrganizationGroup(
    organizationGroup: $organizationGroup
    archiveStatusDeleteStatus: $archiveStatusDeleteStatus
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      goalID
      organizationGroup
      parentGoalID
      departmentID
      directorIDs
      readerIDs
      goalClassification
      goalTitle
      goalDetail
      measuringType
      startDate
      targetDate
      goalType
      publicScope
      archiveStatus
      startValue
      targetValue
      createdAt
      updatedAt
      deleteStatus
      goalWeightPoint
      goalProgressList {
        items {
          goalProgressID
          organizationGroup
          goalID
          progress
          progressDetail
          value
          creatorID
          createdAt
          deleteStatus
          updatedAt
          comments {
            nextToken
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGoalByOrganizationGroupQueryVariables,
  APITypes.ListGoalByOrganizationGroupQuery
>;
export const listNotDeletedGoalByOrganizationGroup = /* GraphQL */ `query ListNotDeletedGoalByOrganizationGroup(
  $organizationGroup: String
  $deleteStatus: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelGoalFilterInput
  $limit: Int
  $nextToken: String
) {
  listNotDeletedGoalByOrganizationGroup(
    organizationGroup: $organizationGroup
    deleteStatus: $deleteStatus
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      goalID
      organizationGroup
      parentGoalID
      departmentID
      directorIDs
      readerIDs
      goalClassification
      goalTitle
      goalDetail
      measuringType
      startDate
      targetDate
      goalType
      publicScope
      archiveStatus
      startValue
      targetValue
      createdAt
      updatedAt
      deleteStatus
      goalWeightPoint
      goalProgressList {
        items {
          goalProgressID
          organizationGroup
          goalID
          progress
          progressDetail
          value
          creatorID
          createdAt
          deleteStatus
          updatedAt
          comments {
            nextToken
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNotDeletedGoalByOrganizationGroupQueryVariables,
  APITypes.ListNotDeletedGoalByOrganizationGroupQuery
>;
export const getGoalActivity = /* GraphQL */ `query GetGoalActivity($id: ID!) {
  getGoalActivity(id: $id) {
    id
    userID
    goalID
    keyResultID
    date
    value
    detail
    progress
    createdAt
    updatedAt
    organizationGroup
    adminGroup
    delete
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGoalActivityQueryVariables,
  APITypes.GetGoalActivityQuery
>;
export const listGoalActivitys = /* GraphQL */ `query ListGoalActivitys(
  $filter: ModelGoalActivityFilterInput
  $limit: Int
  $nextToken: String
) {
  listGoalActivitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userID
      goalID
      keyResultID
      date
      value
      detail
      progress
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      delete
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGoalActivitysQueryVariables,
  APITypes.ListGoalActivitysQuery
>;
export const listGoalActivityByGoalId = /* GraphQL */ `query ListGoalActivityByGoalId(
  $goalID: ID
  $date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelGoalActivityFilterInput
  $limit: Int
  $nextToken: String
) {
  listGoalActivityByGoalId(
    goalID: $goalID
    date: $date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      goalID
      keyResultID
      date
      value
      detail
      progress
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      delete
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGoalActivityByGoalIdQueryVariables,
  APITypes.ListGoalActivityByGoalIdQuery
>;
export const listGoalActivityByUser = /* GraphQL */ `query ListGoalActivityByUser(
  $userID: ID
  $date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelGoalActivityFilterInput
  $limit: Int
  $nextToken: String
) {
  listGoalActivityByUser(
    userID: $userID
    date: $date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      goalID
      keyResultID
      date
      value
      detail
      progress
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      delete
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGoalActivityByUserQueryVariables,
  APITypes.ListGoalActivityByUserQuery
>;
export const listGoalActivityByAdminGroup = /* GraphQL */ `query ListGoalActivityByAdminGroup(
  $adminGroup: String
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelGoalActivityFilterInput
  $limit: Int
  $nextToken: String
) {
  listGoalActivityByAdminGroup(
    adminGroup: $adminGroup
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      goalID
      keyResultID
      date
      value
      detail
      progress
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      delete
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGoalActivityByAdminGroupQueryVariables,
  APITypes.ListGoalActivityByAdminGroupQuery
>;
export const getGoalProgress = /* GraphQL */ `query GetGoalProgress($goalProgressID: ID!) {
  getGoalProgress(goalProgressID: $goalProgressID) {
    goalProgressID
    organizationGroup
    goalID
    progress
    progressDetail
    value
    creatorID
    createdAt
    deleteStatus
    updatedAt
    comments {
      items {
        goalProgressCommentID
        organizationGroup
        commenterID
        commentContent
        goalProgressID
        createdAt
        updatedAt
        commenter {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGoalProgressQueryVariables,
  APITypes.GetGoalProgressQuery
>;
export const listGoalProgresss = /* GraphQL */ `query ListGoalProgresss(
  $goalProgressID: ID
  $filter: ModelGoalProgressFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listGoalProgresss(
    goalProgressID: $goalProgressID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      goalProgressID
      organizationGroup
      goalID
      progress
      progressDetail
      value
      creatorID
      createdAt
      deleteStatus
      updatedAt
      comments {
        items {
          goalProgressCommentID
          organizationGroup
          commenterID
          commentContent
          goalProgressID
          createdAt
          updatedAt
          commenter {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGoalProgresssQueryVariables,
  APITypes.ListGoalProgresssQuery
>;
export const listGoalProgressByGoalID = /* GraphQL */ `query ListGoalProgressByGoalID(
  $goalID: ID
  $deleteStatus: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelGoalProgressFilterInput
  $limit: Int
  $nextToken: String
) {
  listGoalProgressByGoalID(
    goalID: $goalID
    deleteStatus: $deleteStatus
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      goalProgressID
      organizationGroup
      goalID
      progress
      progressDetail
      value
      creatorID
      createdAt
      deleteStatus
      updatedAt
      comments {
        items {
          goalProgressCommentID
          organizationGroup
          commenterID
          commentContent
          goalProgressID
          createdAt
          updatedAt
          commenter {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGoalProgressByGoalIDQueryVariables,
  APITypes.ListGoalProgressByGoalIDQuery
>;
export const getGoalProgressComment = /* GraphQL */ `query GetGoalProgressComment($goalProgressCommentID: ID!) {
  getGoalProgressComment(goalProgressCommentID: $goalProgressCommentID) {
    goalProgressCommentID
    organizationGroup
    commenterID
    commentContent
    goalProgressID
    createdAt
    updatedAt
    commenter {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGoalProgressCommentQueryVariables,
  APITypes.GetGoalProgressCommentQuery
>;
export const listGoalProgressComments = /* GraphQL */ `query ListGoalProgressComments(
  $goalProgressCommentID: ID
  $filter: ModelGoalProgressCommentFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listGoalProgressComments(
    goalProgressCommentID: $goalProgressCommentID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      goalProgressCommentID
      organizationGroup
      commenterID
      commentContent
      goalProgressID
      createdAt
      updatedAt
      commenter {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGoalProgressCommentsQueryVariables,
  APITypes.ListGoalProgressCommentsQuery
>;
export const listCommentsByGoalProgressID = /* GraphQL */ `query ListCommentsByGoalProgressID(
  $goalProgressID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelGoalProgressCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  listCommentsByGoalProgressID(
    goalProgressID: $goalProgressID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      goalProgressCommentID
      organizationGroup
      commenterID
      commentContent
      goalProgressID
      createdAt
      updatedAt
      commenter {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCommentsByGoalProgressIDQueryVariables,
  APITypes.ListCommentsByGoalProgressIDQuery
>;
export const getOno = /* GraphQL */ `query GetOno($id: ID!) {
  getOno(id: $id) {
    id
    member
    manager
    frequency
    nextMeetingDate
    nextMeetingStart
    templateID
    googleChannelResourceID
    iCalUID
    googleAccountEmail
    status
    editors
    createdAt
    updatedAt
    adminGroup
    organizationGroup
    groupsCanAccess
    delete
    deleteStatus
    outlookUserId
    outlookEventId
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetOnoQueryVariables, APITypes.GetOnoQuery>;
export const listOnos = /* GraphQL */ `query ListOnos($filter: ModelOnoFilterInput, $limit: Int, $nextToken: String) {
  listOnos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      member
      manager
      frequency
      nextMeetingDate
      nextMeetingStart
      templateID
      googleChannelResourceID
      iCalUID
      googleAccountEmail
      status
      editors
      createdAt
      updatedAt
      adminGroup
      organizationGroup
      groupsCanAccess
      delete
      deleteStatus
      outlookUserId
      outlookEventId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListOnosQueryVariables, APITypes.ListOnosQuery>;
export const listOnoByGroup = /* GraphQL */ `query ListOnoByGroup(
  $adminGroup: String
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOnoFilterInput
  $limit: Int
  $nextToken: String
) {
  listOnoByGroup(
    adminGroup: $adminGroup
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      member
      manager
      frequency
      nextMeetingDate
      nextMeetingStart
      templateID
      googleChannelResourceID
      iCalUID
      googleAccountEmail
      status
      editors
      createdAt
      updatedAt
      adminGroup
      organizationGroup
      groupsCanAccess
      delete
      deleteStatus
      outlookUserId
      outlookEventId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOnoByGroupQueryVariables,
  APITypes.ListOnoByGroupQuery
>;
export const listOnoByAdminGroupWithDeletion = /* GraphQL */ `query ListOnoByAdminGroupWithDeletion(
  $adminGroup: String
  $deleteStatusUpdatedAt: ModelOnoListOnoByAdminGroupWithDeletionCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOnoFilterInput
  $limit: Int
  $nextToken: String
) {
  listOnoByAdminGroupWithDeletion(
    adminGroup: $adminGroup
    deleteStatusUpdatedAt: $deleteStatusUpdatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      member
      manager
      frequency
      nextMeetingDate
      nextMeetingStart
      templateID
      googleChannelResourceID
      iCalUID
      googleAccountEmail
      status
      editors
      createdAt
      updatedAt
      adminGroup
      organizationGroup
      groupsCanAccess
      delete
      deleteStatus
      outlookUserId
      outlookEventId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOnoByAdminGroupWithDeletionQueryVariables,
  APITypes.ListOnoByAdminGroupWithDeletionQuery
>;
export const getMeeting = /* GraphQL */ `query GetMeeting($id: ID!) {
  getMeeting(id: $id) {
    id
    member
    manager
    startDate
    ono
    status
    templateID
    temporary
    createdAt
    updatedAt
    adminGroup
    organizationGroup
    delete
    deleteStatus
    participated
    groupsCanAccess
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMeetingQueryVariables,
  APITypes.GetMeetingQuery
>;
export const listMeetings = /* GraphQL */ `query ListMeetings(
  $filter: ModelMeetingFilterInput
  $limit: Int
  $nextToken: String
) {
  listMeetings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      member
      manager
      startDate
      ono
      status
      templateID
      temporary
      createdAt
      updatedAt
      adminGroup
      organizationGroup
      delete
      deleteStatus
      participated
      groupsCanAccess
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMeetingsQueryVariables,
  APITypes.ListMeetingsQuery
>;
export const listMeetingByMember = /* GraphQL */ `query ListMeetingByMember(
  $member: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMeetingFilterInput
  $limit: Int
  $nextToken: String
) {
  listMeetingByMember(
    member: $member
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      member
      manager
      startDate
      ono
      status
      templateID
      temporary
      createdAt
      updatedAt
      adminGroup
      organizationGroup
      delete
      deleteStatus
      participated
      groupsCanAccess
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMeetingByMemberQueryVariables,
  APITypes.ListMeetingByMemberQuery
>;
export const listMeetingByOno = /* GraphQL */ `query ListMeetingByOno(
  $ono: ID
  $startDate: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMeetingFilterInput
  $limit: Int
  $nextToken: String
) {
  listMeetingByOno(
    ono: $ono
    startDate: $startDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      member
      manager
      startDate
      ono
      status
      templateID
      temporary
      createdAt
      updatedAt
      adminGroup
      organizationGroup
      delete
      deleteStatus
      participated
      groupsCanAccess
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMeetingByOnoQueryVariables,
  APITypes.ListMeetingByOnoQuery
>;
export const listMeetingByOnoWithDeletion = /* GraphQL */ `query ListMeetingByOnoWithDeletion(
  $ono: ID
  $startDateDeleteStatus: ModelMeetingListMeetingByOnoWithDeletionCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMeetingFilterInput
  $limit: Int
  $nextToken: String
) {
  listMeetingByOnoWithDeletion(
    ono: $ono
    startDateDeleteStatus: $startDateDeleteStatus
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      member
      manager
      startDate
      ono
      status
      templateID
      temporary
      createdAt
      updatedAt
      adminGroup
      organizationGroup
      delete
      deleteStatus
      participated
      groupsCanAccess
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMeetingByOnoWithDeletionQueryVariables,
  APITypes.ListMeetingByOnoWithDeletionQuery
>;
export const listMeetingsByAdminGroup = /* GraphQL */ `query ListMeetingsByAdminGroup(
  $adminGroup: String
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMeetingFilterInput
  $limit: Int
  $nextToken: String
) {
  listMeetingsByAdminGroup(
    adminGroup: $adminGroup
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      member
      manager
      startDate
      ono
      status
      templateID
      temporary
      createdAt
      updatedAt
      adminGroup
      organizationGroup
      delete
      deleteStatus
      participated
      groupsCanAccess
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMeetingsByAdminGroupQueryVariables,
  APITypes.ListMeetingsByAdminGroupQuery
>;
export const getTodo = /* GraphQL */ `query GetTodo($id: ID!) {
  getTodo(id: $id) {
    id
    name
    ono
    assignmentUserId
    status
    createdMeetingID
    completedMeetingID
    createdAt
    updatedAt
    organizationGroup
    adminGroup
    delete
    deleteStatus
    owners
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTodoQueryVariables, APITypes.GetTodoQuery>;
export const listTodos = /* GraphQL */ `query ListTodos(
  $filter: ModelTodoFilterInput
  $limit: Int
  $nextToken: String
) {
  listTodos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      ono
      assignmentUserId
      status
      createdMeetingID
      completedMeetingID
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      delete
      deleteStatus
      owners
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListTodosQueryVariables, APITypes.ListTodosQuery>;
export const listTodoByGroup = /* GraphQL */ `query ListTodoByGroup(
  $adminGroup: String
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTodoFilterInput
  $limit: Int
  $nextToken: String
) {
  listTodoByGroup(
    adminGroup: $adminGroup
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      ono
      assignmentUserId
      status
      createdMeetingID
      completedMeetingID
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      delete
      deleteStatus
      owners
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTodoByGroupQueryVariables,
  APITypes.ListTodoByGroupQuery
>;
export const listTodoByOnoWithDeletion = /* GraphQL */ `query ListTodoByOnoWithDeletion(
  $ono: ID
  $deleteStatusUpdatedAt: ModelTodoListTodoByOnoWithDeletionCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTodoFilterInput
  $limit: Int
  $nextToken: String
) {
  listTodoByOnoWithDeletion(
    ono: $ono
    deleteStatusUpdatedAt: $deleteStatusUpdatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      ono
      assignmentUserId
      status
      createdMeetingID
      completedMeetingID
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      delete
      deleteStatus
      owners
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTodoByOnoWithDeletionQueryVariables,
  APITypes.ListTodoByOnoWithDeletionQuery
>;
export const listTodoByAssignmentUserIdWithDeletion = /* GraphQL */ `query ListTodoByAssignmentUserIdWithDeletion(
  $assignmentUserId: ID
  $deleteStatusUpdatedAt: ModelTodoListTodoByAssignmentUserIdWithDeletionCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTodoFilterInput
  $limit: Int
  $nextToken: String
) {
  listTodoByAssignmentUserIdWithDeletion(
    assignmentUserId: $assignmentUserId
    deleteStatusUpdatedAt: $deleteStatusUpdatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      ono
      assignmentUserId
      status
      createdMeetingID
      completedMeetingID
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      delete
      deleteStatus
      owners
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTodoByAssignmentUserIdWithDeletionQueryVariables,
  APITypes.ListTodoByAssignmentUserIdWithDeletionQuery
>;
export const getNextAction = /* GraphQL */ `query GetNextAction($id: ID!) {
  getNextAction(id: $id) {
    id
    title
    oneOnOneSettingID
    managerID
    addedOneOnOneID
    completedOneOnOneID
    targetDate
    isDone
    createdAt
    updatedAt
    organizationGroup
    manager {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNextActionQueryVariables,
  APITypes.GetNextActionQuery
>;
export const listNextActions = /* GraphQL */ `query ListNextActions(
  $filter: ModelNextActionFilterInput
  $limit: Int
  $nextToken: String
) {
  listNextActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      oneOnOneSettingID
      managerID
      addedOneOnOneID
      completedOneOnOneID
      targetDate
      isDone
      createdAt
      updatedAt
      organizationGroup
      manager {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNextActionsQueryVariables,
  APITypes.ListNextActionsQuery
>;
export const listNextActionByManager = /* GraphQL */ `query ListNextActionByManager(
  $managerID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelNextActionFilterInput
  $limit: Int
  $nextToken: String
) {
  listNextActionByManager(
    managerID: $managerID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      oneOnOneSettingID
      managerID
      addedOneOnOneID
      completedOneOnOneID
      targetDate
      isDone
      createdAt
      updatedAt
      organizationGroup
      manager {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNextActionByManagerQueryVariables,
  APITypes.ListNextActionByManagerQuery
>;
export const listNextActionByOneOnOneSetting = /* GraphQL */ `query ListNextActionByOneOnOneSetting(
  $oneOnOneSettingID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelNextActionFilterInput
  $limit: Int
  $nextToken: String
) {
  listNextActionByOneOnOneSetting(
    oneOnOneSettingID: $oneOnOneSettingID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      oneOnOneSettingID
      managerID
      addedOneOnOneID
      completedOneOnOneID
      targetDate
      isDone
      createdAt
      updatedAt
      organizationGroup
      manager {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNextActionByOneOnOneSettingQueryVariables,
  APITypes.ListNextActionByOneOnOneSettingQuery
>;
export const listNextActionByGroup = /* GraphQL */ `query ListNextActionByGroup(
  $organizationGroup: String
  $sortDirection: ModelSortDirection
  $filter: ModelNextActionFilterInput
  $limit: Int
  $nextToken: String
) {
  listNextActionByGroup(
    organizationGroup: $organizationGroup
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      oneOnOneSettingID
      managerID
      addedOneOnOneID
      completedOneOnOneID
      targetDate
      isDone
      createdAt
      updatedAt
      organizationGroup
      manager {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNextActionByGroupQueryVariables,
  APITypes.ListNextActionByGroupQuery
>;
export const getOrganizationAgenda = /* GraphQL */ `query GetOrganizationAgenda($id: ID!) {
  getOrganizationAgenda(id: $id) {
    id
    title
    order
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationAgendaQueryVariables,
  APITypes.GetOrganizationAgendaQuery
>;
export const listOrganizationAgendas = /* GraphQL */ `query ListOrganizationAgendas(
  $filter: ModelOrganizationAgendaFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrganizationAgendas(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      order
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrganizationAgendasQueryVariables,
  APITypes.ListOrganizationAgendasQuery
>;
export const listOrganizationAgendaByGroup = /* GraphQL */ `query ListOrganizationAgendaByGroup(
  $organizationGroup: String
  $sortDirection: ModelSortDirection
  $filter: ModelOrganizationAgendaFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrganizationAgendaByGroup(
    organizationGroup: $organizationGroup
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      order
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrganizationAgendaByGroupQueryVariables,
  APITypes.ListOrganizationAgendaByGroupQuery
>;
export const getAgendaTemplate = /* GraphQL */ `query GetAgendaTemplate($id: ID!) {
  getAgendaTemplate(id: $id) {
    id
    title
    purpose
    organizationGroup
    ownerID
    status
    createdAt
    updatedAt
    owner {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    agendas {
      items {
        id
        agendaTemplateID
        title
        order
        ownerID
        createdAt
        updatedAt
        organizationGroup
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAgendaTemplateQueryVariables,
  APITypes.GetAgendaTemplateQuery
>;
export const listAgendaTemplates = /* GraphQL */ `query ListAgendaTemplates(
  $filter: ModelAgendaTemplateFilterInput
  $limit: Int
  $nextToken: String
) {
  listAgendaTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      purpose
      organizationGroup
      ownerID
      status
      createdAt
      updatedAt
      owner {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      agendas {
        items {
          id
          agendaTemplateID
          title
          order
          ownerID
          createdAt
          updatedAt
          organizationGroup
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAgendaTemplatesQueryVariables,
  APITypes.ListAgendaTemplatesQuery
>;
export const listAgendaTemplateByOrganizationGroup = /* GraphQL */ `query ListAgendaTemplateByOrganizationGroup(
  $organizationGroup: String
  $sortDirection: ModelSortDirection
  $filter: ModelAgendaTemplateFilterInput
  $limit: Int
  $nextToken: String
) {
  listAgendaTemplateByOrganizationGroup(
    organizationGroup: $organizationGroup
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      purpose
      organizationGroup
      ownerID
      status
      createdAt
      updatedAt
      owner {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      agendas {
        items {
          id
          agendaTemplateID
          title
          order
          ownerID
          createdAt
          updatedAt
          organizationGroup
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAgendaTemplateByOrganizationGroupQueryVariables,
  APITypes.ListAgendaTemplateByOrganizationGroupQuery
>;
export const getAgendaTemplateAgenda = /* GraphQL */ `query GetAgendaTemplateAgenda($id: ID!) {
  getAgendaTemplateAgenda(id: $id) {
    id
    agendaTemplateID
    title
    order
    ownerID
    createdAt
    updatedAt
    organizationGroup
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAgendaTemplateAgendaQueryVariables,
  APITypes.GetAgendaTemplateAgendaQuery
>;
export const listAgendaTemplateAgendas = /* GraphQL */ `query ListAgendaTemplateAgendas(
  $filter: ModelAgendaTemplateAgendaFilterInput
  $limit: Int
  $nextToken: String
) {
  listAgendaTemplateAgendas(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      agendaTemplateID
      title
      order
      ownerID
      createdAt
      updatedAt
      organizationGroup
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAgendaTemplateAgendasQueryVariables,
  APITypes.ListAgendaTemplateAgendasQuery
>;
export const listAgendaTemplateAgendaByGroup = /* GraphQL */ `query ListAgendaTemplateAgendaByGroup(
  $organizationGroup: String
  $sortDirection: ModelSortDirection
  $filter: ModelAgendaTemplateAgendaFilterInput
  $limit: Int
  $nextToken: String
) {
  listAgendaTemplateAgendaByGroup(
    organizationGroup: $organizationGroup
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      agendaTemplateID
      title
      order
      ownerID
      createdAt
      updatedAt
      organizationGroup
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAgendaTemplateAgendaByGroupQueryVariables,
  APITypes.ListAgendaTemplateAgendaByGroupQuery
>;
export const listAgendaTemplateAgendaByAgendaTemplateID = /* GraphQL */ `query ListAgendaTemplateAgendaByAgendaTemplateID(
  $agendaTemplateID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelAgendaTemplateAgendaFilterInput
  $limit: Int
  $nextToken: String
) {
  listAgendaTemplateAgendaByAgendaTemplateID(
    agendaTemplateID: $agendaTemplateID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      agendaTemplateID
      title
      order
      ownerID
      createdAt
      updatedAt
      organizationGroup
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAgendaTemplateAgendaByAgendaTemplateIDQueryVariables,
  APITypes.ListAgendaTemplateAgendaByAgendaTemplateIDQuery
>;
export const getTemplate = /* GraphQL */ `query GetTemplate($id: ID!) {
  getTemplate(id: $id) {
    id
    title
    ono
    organizationGroup
    adminGroup
    createdAt
    updatedAt
    delete
    deleteStatus
    groupsCanAccess
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTemplateQueryVariables,
  APITypes.GetTemplateQuery
>;
export const listTemplates = /* GraphQL */ `query ListTemplates(
  $filter: ModelTemplateFilterInput
  $limit: Int
  $nextToken: String
) {
  listTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      ono
      organizationGroup
      adminGroup
      createdAt
      updatedAt
      delete
      deleteStatus
      groupsCanAccess
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTemplatesQueryVariables,
  APITypes.ListTemplatesQuery
>;
export const listTemplateByAdminGroup = /* GraphQL */ `query ListTemplateByAdminGroup(
  $adminGroup: String
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTemplateFilterInput
  $limit: Int
  $nextToken: String
) {
  listTemplateByAdminGroup(
    adminGroup: $adminGroup
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      ono
      organizationGroup
      adminGroup
      createdAt
      updatedAt
      delete
      deleteStatus
      groupsCanAccess
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTemplateByAdminGroupQueryVariables,
  APITypes.ListTemplateByAdminGroupQuery
>;
export const listTemplateByAdminGroupWithDeletion = /* GraphQL */ `query ListTemplateByAdminGroupWithDeletion(
  $adminGroup: String
  $deleteStatusUpdatedAt: ModelTemplateListTemplateByAdminGroupWithDeletionCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTemplateFilterInput
  $limit: Int
  $nextToken: String
) {
  listTemplateByAdminGroupWithDeletion(
    adminGroup: $adminGroup
    deleteStatusUpdatedAt: $deleteStatusUpdatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      ono
      organizationGroup
      adminGroup
      createdAt
      updatedAt
      delete
      deleteStatus
      groupsCanAccess
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTemplateByAdminGroupWithDeletionQueryVariables,
  APITypes.ListTemplateByAdminGroupWithDeletionQuery
>;
export const getTemplateAgenda = /* GraphQL */ `query GetTemplateAgenda($id: ID!) {
  getTemplateAgenda(id: $id) {
    id
    template
    title
    fromOrganization
    order
    organizationGroup
    adminGroup
    createdAt
    updatedAt
    delete
    deleteStatus
    groupsCanAccess
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTemplateAgendaQueryVariables,
  APITypes.GetTemplateAgendaQuery
>;
export const listTemplateAgendas = /* GraphQL */ `query ListTemplateAgendas(
  $filter: ModelTemplateAgendaFilterInput
  $limit: Int
  $nextToken: String
) {
  listTemplateAgendas(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      template
      title
      fromOrganization
      order
      organizationGroup
      adminGroup
      createdAt
      updatedAt
      delete
      deleteStatus
      groupsCanAccess
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTemplateAgendasQueryVariables,
  APITypes.ListTemplateAgendasQuery
>;
export const listTemplateAgendaByTemplate = /* GraphQL */ `query ListTemplateAgendaByTemplate(
  $template: ID
  $order: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTemplateAgendaFilterInput
  $limit: Int
  $nextToken: String
) {
  listTemplateAgendaByTemplate(
    template: $template
    order: $order
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      template
      title
      fromOrganization
      order
      organizationGroup
      adminGroup
      createdAt
      updatedAt
      delete
      deleteStatus
      groupsCanAccess
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTemplateAgendaByTemplateQueryVariables,
  APITypes.ListTemplateAgendaByTemplateQuery
>;
export const listTemplateAgendaByTemplateWithDeletion = /* GraphQL */ `query ListTemplateAgendaByTemplateWithDeletion(
  $template: ID
  $deleteStatusOrder: ModelTemplateAgendaListTemplateAgendaByTemplateWithDeletionCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTemplateAgendaFilterInput
  $limit: Int
  $nextToken: String
) {
  listTemplateAgendaByTemplateWithDeletion(
    template: $template
    deleteStatusOrder: $deleteStatusOrder
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      template
      title
      fromOrganization
      order
      organizationGroup
      adminGroup
      createdAt
      updatedAt
      delete
      deleteStatus
      groupsCanAccess
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTemplateAgendaByTemplateWithDeletionQueryVariables,
  APITypes.ListTemplateAgendaByTemplateWithDeletionQuery
>;
export const listTemplateAgendaByFromOrganization = /* GraphQL */ `query ListTemplateAgendaByFromOrganization(
  $fromOrganization: ID
  $order: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTemplateAgendaFilterInput
  $limit: Int
  $nextToken: String
) {
  listTemplateAgendaByFromOrganization(
    fromOrganization: $fromOrganization
    order: $order
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      template
      title
      fromOrganization
      order
      organizationGroup
      adminGroup
      createdAt
      updatedAt
      delete
      deleteStatus
      groupsCanAccess
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTemplateAgendaByFromOrganizationQueryVariables,
  APITypes.ListTemplateAgendaByFromOrganizationQuery
>;
export const listTemplateAgendaByFromOrganizationWithDeletion = /* GraphQL */ `query ListTemplateAgendaByFromOrganizationWithDeletion(
  $fromOrganization: ID
  $deleteStatusOrder: ModelTemplateAgendaListTemplateAgendaByFromOrganizationWithDeletionCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTemplateAgendaFilterInput
  $limit: Int
  $nextToken: String
) {
  listTemplateAgendaByFromOrganizationWithDeletion(
    fromOrganization: $fromOrganization
    deleteStatusOrder: $deleteStatusOrder
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      template
      title
      fromOrganization
      order
      organizationGroup
      adminGroup
      createdAt
      updatedAt
      delete
      deleteStatus
      groupsCanAccess
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTemplateAgendaByFromOrganizationWithDeletionQueryVariables,
  APITypes.ListTemplateAgendaByFromOrganizationWithDeletionQuery
>;
export const listTemplateAgendaByAdminGroup = /* GraphQL */ `query ListTemplateAgendaByAdminGroup(
  $adminGroup: String
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTemplateAgendaFilterInput
  $limit: Int
  $nextToken: String
) {
  listTemplateAgendaByAdminGroup(
    adminGroup: $adminGroup
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      template
      title
      fromOrganization
      order
      organizationGroup
      adminGroup
      createdAt
      updatedAt
      delete
      deleteStatus
      groupsCanAccess
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTemplateAgendaByAdminGroupQueryVariables,
  APITypes.ListTemplateAgendaByAdminGroupQuery
>;
export const getAgenda = /* GraphQL */ `query GetAgenda($id: ID!) {
  getAgenda(id: $id) {
    id
    oneOnOneID
    title
    order
    referenceID
    agendaType
    isDone
    organizationGroup
    createdAt
    updatedAt
    owners
    outcome
    process
    creator {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    comments {
      items {
        id
        oneOnOneID
        agendaID
        comment
        commenterID
        organizationGroup
        aiAdvice
        createdAt
        updatedAt
        commenter {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetAgendaQueryVariables, APITypes.GetAgendaQuery>;
export const listAgendas = /* GraphQL */ `query ListAgendas(
  $filter: ModelAgendaFilterInput
  $limit: Int
  $nextToken: String
) {
  listAgendas(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      oneOnOneID
      title
      order
      referenceID
      agendaType
      isDone
      organizationGroup
      createdAt
      updatedAt
      owners
      outcome
      process
      creator {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      comments {
        items {
          id
          oneOnOneID
          agendaID
          comment
          commenterID
          organizationGroup
          aiAdvice
          createdAt
          updatedAt
          commenter {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAgendasQueryVariables,
  APITypes.ListAgendasQuery
>;
export const listAgendaByGroup = /* GraphQL */ `query ListAgendaByGroup(
  $organizationGroup: String
  $sortDirection: ModelSortDirection
  $filter: ModelAgendaFilterInput
  $limit: Int
  $nextToken: String
) {
  listAgendaByGroup(
    organizationGroup: $organizationGroup
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      oneOnOneID
      title
      order
      referenceID
      agendaType
      isDone
      organizationGroup
      createdAt
      updatedAt
      owners
      outcome
      process
      creator {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      comments {
        items {
          id
          oneOnOneID
          agendaID
          comment
          commenterID
          organizationGroup
          aiAdvice
          createdAt
          updatedAt
          commenter {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAgendaByGroupQueryVariables,
  APITypes.ListAgendaByGroupQuery
>;
export const listAgendaByOneOnOneID = /* GraphQL */ `query ListAgendaByOneOnOneID(
  $oneOnOneID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelAgendaFilterInput
  $limit: Int
  $nextToken: String
) {
  listAgendaByOneOnOneID(
    oneOnOneID: $oneOnOneID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      oneOnOneID
      title
      order
      referenceID
      agendaType
      isDone
      organizationGroup
      createdAt
      updatedAt
      owners
      outcome
      process
      creator {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      comments {
        items {
          id
          oneOnOneID
          agendaID
          comment
          commenterID
          organizationGroup
          aiAdvice
          createdAt
          updatedAt
          commenter {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAgendaByOneOnOneIDQueryVariables,
  APITypes.ListAgendaByOneOnOneIDQuery
>;
export const getAgendaComment = /* GraphQL */ `query GetAgendaComment($id: ID!) {
  getAgendaComment(id: $id) {
    id
    oneOnOneID
    agendaID
    comment
    commenterID
    organizationGroup
    aiAdvice
    createdAt
    updatedAt
    commenter {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAgendaCommentQueryVariables,
  APITypes.GetAgendaCommentQuery
>;
export const listAgendaComments = /* GraphQL */ `query ListAgendaComments(
  $filter: ModelAgendaCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  listAgendaComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      oneOnOneID
      agendaID
      comment
      commenterID
      organizationGroup
      aiAdvice
      createdAt
      updatedAt
      commenter {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAgendaCommentsQueryVariables,
  APITypes.ListAgendaCommentsQuery
>;
export const listAgendaCommentByAgendaID = /* GraphQL */ `query ListAgendaCommentByAgendaID(
  $agendaID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelAgendaCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  listAgendaCommentByAgendaID(
    agendaID: $agendaID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      oneOnOneID
      agendaID
      comment
      commenterID
      organizationGroup
      aiAdvice
      createdAt
      updatedAt
      commenter {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAgendaCommentByAgendaIDQueryVariables,
  APITypes.ListAgendaCommentByAgendaIDQuery
>;
export const listAgendaCommentByGroup = /* GraphQL */ `query ListAgendaCommentByGroup(
  $organizationGroup: String
  $sortDirection: ModelSortDirection
  $filter: ModelAgendaCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  listAgendaCommentByGroup(
    organizationGroup: $organizationGroup
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      oneOnOneID
      agendaID
      comment
      commenterID
      organizationGroup
      aiAdvice
      createdAt
      updatedAt
      commenter {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAgendaCommentByGroupQueryVariables,
  APITypes.ListAgendaCommentByGroupQuery
>;
export const getNote = /* GraphQL */ `query GetNote($id: ID!) {
  getNote(id: $id) {
    id
    meeting
    agendaID
    referenceID
    user
    note
    type
    owners
    organizationGroup
    adminGroup
    createdAt
    updatedAt
    delete
    deleteStatus
    groupsCanAccess
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetNoteQueryVariables, APITypes.GetNoteQuery>;
export const listNotes = /* GraphQL */ `query ListNotes(
  $filter: ModelNoteFilterInput
  $limit: Int
  $nextToken: String
) {
  listNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      meeting
      agendaID
      referenceID
      user
      note
      type
      owners
      organizationGroup
      adminGroup
      createdAt
      updatedAt
      delete
      deleteStatus
      groupsCanAccess
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListNotesQueryVariables, APITypes.ListNotesQuery>;
export const listNoteByMeetingWithDeletion = /* GraphQL */ `query ListNoteByMeetingWithDeletion(
  $meeting: ID
  $deleteStatusType: ModelNoteListNoteByMeetingWithDeletionCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNoteFilterInput
  $limit: Int
  $nextToken: String
) {
  listNoteByMeetingWithDeletion(
    meeting: $meeting
    deleteStatusType: $deleteStatusType
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      meeting
      agendaID
      referenceID
      user
      note
      type
      owners
      organizationGroup
      adminGroup
      createdAt
      updatedAt
      delete
      deleteStatus
      groupsCanAccess
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNoteByMeetingWithDeletionQueryVariables,
  APITypes.ListNoteByMeetingWithDeletionQuery
>;
export const listNoteByMeeting = /* GraphQL */ `query ListNoteByMeeting(
  $meeting: ID
  $type: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNoteFilterInput
  $limit: Int
  $nextToken: String
) {
  listNoteByMeeting(
    meeting: $meeting
    type: $type
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      meeting
      agendaID
      referenceID
      user
      note
      type
      owners
      organizationGroup
      adminGroup
      createdAt
      updatedAt
      delete
      deleteStatus
      groupsCanAccess
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNoteByMeetingQueryVariables,
  APITypes.ListNoteByMeetingQuery
>;
export const listNoteByGroup = /* GraphQL */ `query ListNoteByGroup(
  $adminGroup: String
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNoteFilterInput
  $limit: Int
  $nextToken: String
) {
  listNoteByGroup(
    adminGroup: $adminGroup
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      meeting
      agendaID
      referenceID
      user
      note
      type
      owners
      organizationGroup
      adminGroup
      createdAt
      updatedAt
      delete
      deleteStatus
      groupsCanAccess
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNoteByGroupQueryVariables,
  APITypes.ListNoteByGroupQuery
>;
export const listNoteByAgendaID = /* GraphQL */ `query ListNoteByAgendaID(
  $agendaID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelNoteFilterInput
  $limit: Int
  $nextToken: String
) {
  listNoteByAgendaID(
    agendaID: $agendaID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      meeting
      agendaID
      referenceID
      user
      note
      type
      owners
      organizationGroup
      adminGroup
      createdAt
      updatedAt
      delete
      deleteStatus
      groupsCanAccess
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNoteByAgendaIDQueryVariables,
  APITypes.ListNoteByAgendaIDQuery
>;
export const getOneOnOneSetting = /* GraphQL */ `query GetOneOnOneSetting($id: ID!) {
  getOneOnOneSetting(id: $id) {
    id
    userID1
    userID2
    frequency
    nextOneOnOneDate
    nextOneOnOneTime
    agendaTemplateID
    status
    organizationGroup
    createdAt
    updatedAt
    calendarIntegrationSetting {
      calendarApp
      googleIntegration {
        calendarId
        recurrenceRule
        __typename
      }
      outlookIntegration {
        userId
        seriesMasterId
        __typename
      }
      __typename
    }
    user1 {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    user2 {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    oneOnOneList {
      items {
        id
        userID1
        userID2
        managerID
        oneOnOneSettingID
        frequency
        startDate
        startTime
        status
        agendaTemplateID
        recordingAgendaID
        recordingTarget
        aiSummary
        organizationGroup
        createdAt
        updatedAt
        calendarIntegration {
          calendarApp
          googleIntegration {
            calendarId
            recurrenceRule
            __typename
          }
          outlookIntegration {
            eventType
            userId
            seriesMasterId
            __typename
          }
          __typename
        }
        user1 {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        user2 {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        oneOnOneSetting {
          id
          userID1
          userID2
          frequency
          nextOneOnOneDate
          nextOneOnOneTime
          agendaTemplateID
          status
          organizationGroup
          createdAt
          updatedAt
          calendarIntegrationSetting {
            calendarApp
            __typename
          }
          user1 {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          user2 {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          oneOnOneList {
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOneOnOneSettingQueryVariables,
  APITypes.GetOneOnOneSettingQuery
>;
export const listOneOnOneSettings = /* GraphQL */ `query ListOneOnOneSettings(
  $filter: ModelOneOnOneSettingFilterInput
  $limit: Int
  $nextToken: String
) {
  listOneOnOneSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userID1
      userID2
      frequency
      nextOneOnOneDate
      nextOneOnOneTime
      agendaTemplateID
      status
      organizationGroup
      createdAt
      updatedAt
      calendarIntegrationSetting {
        calendarApp
        googleIntegration {
          calendarId
          recurrenceRule
          __typename
        }
        outlookIntegration {
          userId
          seriesMasterId
          __typename
        }
        __typename
      }
      user1 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      user2 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      oneOnOneList {
        items {
          id
          userID1
          userID2
          managerID
          oneOnOneSettingID
          frequency
          startDate
          startTime
          status
          agendaTemplateID
          recordingAgendaID
          recordingTarget
          aiSummary
          organizationGroup
          createdAt
          updatedAt
          calendarIntegration {
            calendarApp
            __typename
          }
          user1 {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          user2 {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          oneOnOneSetting {
            id
            userID1
            userID2
            frequency
            nextOneOnOneDate
            nextOneOnOneTime
            agendaTemplateID
            status
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOneOnOneSettingsQueryVariables,
  APITypes.ListOneOnOneSettingsQuery
>;
export const listOneOnOneSettingByNextStartDate = /* GraphQL */ `query ListOneOnOneSettingByNextStartDate(
  $nextOneOnOneDate: AWSDate
  $sortDirection: ModelSortDirection
  $filter: ModelOneOnOneSettingFilterInput
  $limit: Int
  $nextToken: String
) {
  listOneOnOneSettingByNextStartDate(
    nextOneOnOneDate: $nextOneOnOneDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID1
      userID2
      frequency
      nextOneOnOneDate
      nextOneOnOneTime
      agendaTemplateID
      status
      organizationGroup
      createdAt
      updatedAt
      calendarIntegrationSetting {
        calendarApp
        googleIntegration {
          calendarId
          recurrenceRule
          __typename
        }
        outlookIntegration {
          userId
          seriesMasterId
          __typename
        }
        __typename
      }
      user1 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      user2 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      oneOnOneList {
        items {
          id
          userID1
          userID2
          managerID
          oneOnOneSettingID
          frequency
          startDate
          startTime
          status
          agendaTemplateID
          recordingAgendaID
          recordingTarget
          aiSummary
          organizationGroup
          createdAt
          updatedAt
          calendarIntegration {
            calendarApp
            __typename
          }
          user1 {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          user2 {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          oneOnOneSetting {
            id
            userID1
            userID2
            frequency
            nextOneOnOneDate
            nextOneOnOneTime
            agendaTemplateID
            status
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOneOnOneSettingByNextStartDateQueryVariables,
  APITypes.ListOneOnOneSettingByNextStartDateQuery
>;
export const listOneOnOneSettingByOrganization = /* GraphQL */ `query ListOneOnOneSettingByOrganization(
  $organizationGroup: String
  $sortDirection: ModelSortDirection
  $filter: ModelOneOnOneSettingFilterInput
  $limit: Int
  $nextToken: String
) {
  listOneOnOneSettingByOrganization(
    organizationGroup: $organizationGroup
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID1
      userID2
      frequency
      nextOneOnOneDate
      nextOneOnOneTime
      agendaTemplateID
      status
      organizationGroup
      createdAt
      updatedAt
      calendarIntegrationSetting {
        calendarApp
        googleIntegration {
          calendarId
          recurrenceRule
          __typename
        }
        outlookIntegration {
          userId
          seriesMasterId
          __typename
        }
        __typename
      }
      user1 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      user2 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      oneOnOneList {
        items {
          id
          userID1
          userID2
          managerID
          oneOnOneSettingID
          frequency
          startDate
          startTime
          status
          agendaTemplateID
          recordingAgendaID
          recordingTarget
          aiSummary
          organizationGroup
          createdAt
          updatedAt
          calendarIntegration {
            calendarApp
            __typename
          }
          user1 {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          user2 {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          oneOnOneSetting {
            id
            userID1
            userID2
            frequency
            nextOneOnOneDate
            nextOneOnOneTime
            agendaTemplateID
            status
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOneOnOneSettingByOrganizationQueryVariables,
  APITypes.ListOneOnOneSettingByOrganizationQuery
>;
export const getOneOnOne = /* GraphQL */ `query GetOneOnOne($id: ID!) {
  getOneOnOne(id: $id) {
    id
    userID1
    userID2
    managerID
    oneOnOneSettingID
    frequency
    startDate
    startTime
    status
    agendaTemplateID
    recordingAgendaID
    recordingTarget
    aiSummary
    organizationGroup
    createdAt
    updatedAt
    calendarIntegration {
      calendarApp
      googleIntegration {
        calendarId
        recurrenceRule
        __typename
      }
      outlookIntegration {
        eventType
        userId
        seriesMasterId
        __typename
      }
      __typename
    }
    user1 {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    user2 {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    oneOnOneSetting {
      id
      userID1
      userID2
      frequency
      nextOneOnOneDate
      nextOneOnOneTime
      agendaTemplateID
      status
      organizationGroup
      createdAt
      updatedAt
      calendarIntegrationSetting {
        calendarApp
        googleIntegration {
          calendarId
          recurrenceRule
          __typename
        }
        outlookIntegration {
          userId
          seriesMasterId
          __typename
        }
        __typename
      }
      user1 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      user2 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      oneOnOneList {
        items {
          id
          userID1
          userID2
          managerID
          oneOnOneSettingID
          frequency
          startDate
          startTime
          status
          agendaTemplateID
          recordingAgendaID
          recordingTarget
          aiSummary
          organizationGroup
          createdAt
          updatedAt
          calendarIntegration {
            calendarApp
            __typename
          }
          user1 {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          user2 {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          oneOnOneSetting {
            id
            userID1
            userID2
            frequency
            nextOneOnOneDate
            nextOneOnOneTime
            agendaTemplateID
            status
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOneOnOneQueryVariables,
  APITypes.GetOneOnOneQuery
>;
export const listOneOnOnes = /* GraphQL */ `query ListOneOnOnes(
  $filter: ModelOneOnOneFilterInput
  $limit: Int
  $nextToken: String
) {
  listOneOnOnes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userID1
      userID2
      managerID
      oneOnOneSettingID
      frequency
      startDate
      startTime
      status
      agendaTemplateID
      recordingAgendaID
      recordingTarget
      aiSummary
      organizationGroup
      createdAt
      updatedAt
      calendarIntegration {
        calendarApp
        googleIntegration {
          calendarId
          recurrenceRule
          __typename
        }
        outlookIntegration {
          eventType
          userId
          seriesMasterId
          __typename
        }
        __typename
      }
      user1 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      user2 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      oneOnOneSetting {
        id
        userID1
        userID2
        frequency
        nextOneOnOneDate
        nextOneOnOneTime
        agendaTemplateID
        status
        organizationGroup
        createdAt
        updatedAt
        calendarIntegrationSetting {
          calendarApp
          googleIntegration {
            calendarId
            recurrenceRule
            __typename
          }
          outlookIntegration {
            userId
            seriesMasterId
            __typename
          }
          __typename
        }
        user1 {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        user2 {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        oneOnOneList {
          items {
            id
            userID1
            userID2
            managerID
            oneOnOneSettingID
            frequency
            startDate
            startTime
            status
            agendaTemplateID
            recordingAgendaID
            recordingTarget
            aiSummary
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOneOnOnesQueryVariables,
  APITypes.ListOneOnOnesQuery
>;
export const listOneOnOneBySettingID = /* GraphQL */ `query ListOneOnOneBySettingID(
  $oneOnOneSettingID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelOneOnOneFilterInput
  $limit: Int
  $nextToken: String
) {
  listOneOnOneBySettingID(
    oneOnOneSettingID: $oneOnOneSettingID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID1
      userID2
      managerID
      oneOnOneSettingID
      frequency
      startDate
      startTime
      status
      agendaTemplateID
      recordingAgendaID
      recordingTarget
      aiSummary
      organizationGroup
      createdAt
      updatedAt
      calendarIntegration {
        calendarApp
        googleIntegration {
          calendarId
          recurrenceRule
          __typename
        }
        outlookIntegration {
          eventType
          userId
          seriesMasterId
          __typename
        }
        __typename
      }
      user1 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      user2 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      oneOnOneSetting {
        id
        userID1
        userID2
        frequency
        nextOneOnOneDate
        nextOneOnOneTime
        agendaTemplateID
        status
        organizationGroup
        createdAt
        updatedAt
        calendarIntegrationSetting {
          calendarApp
          googleIntegration {
            calendarId
            recurrenceRule
            __typename
          }
          outlookIntegration {
            userId
            seriesMasterId
            __typename
          }
          __typename
        }
        user1 {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        user2 {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        oneOnOneList {
          items {
            id
            userID1
            userID2
            managerID
            oneOnOneSettingID
            frequency
            startDate
            startTime
            status
            agendaTemplateID
            recordingAgendaID
            recordingTarget
            aiSummary
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOneOnOneBySettingIDQueryVariables,
  APITypes.ListOneOnOneBySettingIDQuery
>;
export const listOneOnOneByStartDate = /* GraphQL */ `query ListOneOnOneByStartDate(
  $startDate: AWSDate
  $sortDirection: ModelSortDirection
  $filter: ModelOneOnOneFilterInput
  $limit: Int
  $nextToken: String
) {
  listOneOnOneByStartDate(
    startDate: $startDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID1
      userID2
      managerID
      oneOnOneSettingID
      frequency
      startDate
      startTime
      status
      agendaTemplateID
      recordingAgendaID
      recordingTarget
      aiSummary
      organizationGroup
      createdAt
      updatedAt
      calendarIntegration {
        calendarApp
        googleIntegration {
          calendarId
          recurrenceRule
          __typename
        }
        outlookIntegration {
          eventType
          userId
          seriesMasterId
          __typename
        }
        __typename
      }
      user1 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      user2 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      oneOnOneSetting {
        id
        userID1
        userID2
        frequency
        nextOneOnOneDate
        nextOneOnOneTime
        agendaTemplateID
        status
        organizationGroup
        createdAt
        updatedAt
        calendarIntegrationSetting {
          calendarApp
          googleIntegration {
            calendarId
            recurrenceRule
            __typename
          }
          outlookIntegration {
            userId
            seriesMasterId
            __typename
          }
          __typename
        }
        user1 {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        user2 {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        oneOnOneList {
          items {
            id
            userID1
            userID2
            managerID
            oneOnOneSettingID
            frequency
            startDate
            startTime
            status
            agendaTemplateID
            recordingAgendaID
            recordingTarget
            aiSummary
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOneOnOneByStartDateQueryVariables,
  APITypes.ListOneOnOneByStartDateQuery
>;
export const listOneOnOneByStartDateInOrganization = /* GraphQL */ `query ListOneOnOneByStartDateInOrganization(
  $organizationGroup: String
  $startDate: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOneOnOneFilterInput
  $limit: Int
  $nextToken: String
) {
  listOneOnOneByStartDateInOrganization(
    organizationGroup: $organizationGroup
    startDate: $startDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID1
      userID2
      managerID
      oneOnOneSettingID
      frequency
      startDate
      startTime
      status
      agendaTemplateID
      recordingAgendaID
      recordingTarget
      aiSummary
      organizationGroup
      createdAt
      updatedAt
      calendarIntegration {
        calendarApp
        googleIntegration {
          calendarId
          recurrenceRule
          __typename
        }
        outlookIntegration {
          eventType
          userId
          seriesMasterId
          __typename
        }
        __typename
      }
      user1 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      user2 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      oneOnOneSetting {
        id
        userID1
        userID2
        frequency
        nextOneOnOneDate
        nextOneOnOneTime
        agendaTemplateID
        status
        organizationGroup
        createdAt
        updatedAt
        calendarIntegrationSetting {
          calendarApp
          googleIntegration {
            calendarId
            recurrenceRule
            __typename
          }
          outlookIntegration {
            userId
            seriesMasterId
            __typename
          }
          __typename
        }
        user1 {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        user2 {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        oneOnOneList {
          items {
            id
            userID1
            userID2
            managerID
            oneOnOneSettingID
            frequency
            startDate
            startTime
            status
            agendaTemplateID
            recordingAgendaID
            recordingTarget
            aiSummary
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOneOnOneByStartDateInOrganizationQueryVariables,
  APITypes.ListOneOnOneByStartDateInOrganizationQuery
>;
export const getShareMemo = /* GraphQL */ `query GetShareMemo($id: ID!) {
  getShareMemo(id: $id) {
    id
    oneOnOneID
    ownerID
    memo
    organizationGroup
    createdAt
    updatedAt
    owner {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetShareMemoQueryVariables,
  APITypes.GetShareMemoQuery
>;
export const listShareMemos = /* GraphQL */ `query ListShareMemos(
  $filter: ModelShareMemoFilterInput
  $limit: Int
  $nextToken: String
) {
  listShareMemos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      oneOnOneID
      ownerID
      memo
      organizationGroup
      createdAt
      updatedAt
      owner {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListShareMemosQueryVariables,
  APITypes.ListShareMemosQuery
>;
export const listShareMemoByOneOnOneID = /* GraphQL */ `query ListShareMemoByOneOnOneID(
  $oneOnOneID: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelShareMemoFilterInput
  $limit: Int
  $nextToken: String
) {
  listShareMemoByOneOnOneID(
    oneOnOneID: $oneOnOneID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      oneOnOneID
      ownerID
      memo
      organizationGroup
      createdAt
      updatedAt
      owner {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListShareMemoByOneOnOneIDQueryVariables,
  APITypes.ListShareMemoByOneOnOneIDQuery
>;
export const listShareMemoByGroup = /* GraphQL */ `query ListShareMemoByGroup(
  $organizationGroup: String
  $sortDirection: ModelSortDirection
  $filter: ModelShareMemoFilterInput
  $limit: Int
  $nextToken: String
) {
  listShareMemoByGroup(
    organizationGroup: $organizationGroup
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      oneOnOneID
      ownerID
      memo
      organizationGroup
      createdAt
      updatedAt
      owner {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListShareMemoByGroupQueryVariables,
  APITypes.ListShareMemoByGroupQuery
>;
export const getPrivateMemo = /* GraphQL */ `query GetPrivateMemo($id: ID!) {
  getPrivateMemo(id: $id) {
    id
    oneOnOneID
    memo
    ownerID
    organizationGroup
    createdAt
    updatedAt
    owner {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPrivateMemoQueryVariables,
  APITypes.GetPrivateMemoQuery
>;
export const listPrivateMemos = /* GraphQL */ `query ListPrivateMemos(
  $filter: ModelPrivateMemoFilterInput
  $limit: Int
  $nextToken: String
) {
  listPrivateMemos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      oneOnOneID
      memo
      ownerID
      organizationGroup
      createdAt
      updatedAt
      owner {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPrivateMemosQueryVariables,
  APITypes.ListPrivateMemosQuery
>;
export const listPrivateMemoByOneOnOneID = /* GraphQL */ `query ListPrivateMemoByOneOnOneID(
  $oneOnOneID: ID
  $ownerID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPrivateMemoFilterInput
  $limit: Int
  $nextToken: String
) {
  listPrivateMemoByOneOnOneID(
    oneOnOneID: $oneOnOneID
    ownerID: $ownerID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      oneOnOneID
      memo
      ownerID
      organizationGroup
      createdAt
      updatedAt
      owner {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPrivateMemoByOneOnOneIDQueryVariables,
  APITypes.ListPrivateMemoByOneOnOneIDQuery
>;
export const getGradeMatrix = /* GraphQL */ `query GetGradeMatrix($gradeMatrixID: ID!) {
  getGradeMatrix(gradeMatrixID: $gradeMatrixID) {
    gradeMatrixID
    title
    description
    organizationGroup
    createdAt
    updatedAt
    memberList {
      items {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    competencyAreaList {
      items {
        competencyAreaID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    gradeList {
      items {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    competencyItemList {
      items {
        competencyItemID
        item
        gradeID
        competencyAreaID
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGradeMatrixQueryVariables,
  APITypes.GetGradeMatrixQuery
>;
export const listGradeMatrixs = /* GraphQL */ `query ListGradeMatrixs(
  $gradeMatrixID: ID
  $filter: ModelGradeMatrixFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listGradeMatrixs(
    gradeMatrixID: $gradeMatrixID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      gradeMatrixID
      title
      description
      organizationGroup
      createdAt
      updatedAt
      memberList {
        items {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      competencyAreaList {
        items {
          competencyAreaID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      gradeList {
        items {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      competencyItemList {
        items {
          competencyItemID
          item
          gradeID
          competencyAreaID
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGradeMatrixsQueryVariables,
  APITypes.ListGradeMatrixsQuery
>;
export const listGradeMatrixByOrganizationGroup = /* GraphQL */ `query ListGradeMatrixByOrganizationGroup(
  $organizationGroup: String
  $sortDirection: ModelSortDirection
  $filter: ModelGradeMatrixFilterInput
  $limit: Int
  $nextToken: String
) {
  listGradeMatrixByOrganizationGroup(
    organizationGroup: $organizationGroup
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      gradeMatrixID
      title
      description
      organizationGroup
      createdAt
      updatedAt
      memberList {
        items {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      competencyAreaList {
        items {
          competencyAreaID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      gradeList {
        items {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      competencyItemList {
        items {
          competencyItemID
          item
          gradeID
          competencyAreaID
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGradeMatrixByOrganizationGroupQueryVariables,
  APITypes.ListGradeMatrixByOrganizationGroupQuery
>;
export const getCompetencyArea = /* GraphQL */ `query GetCompetencyArea($competencyAreaID: ID!) {
  getCompetencyArea(competencyAreaID: $competencyAreaID) {
    competencyAreaID
    title
    description
    gradeMatrixID
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCompetencyAreaQueryVariables,
  APITypes.GetCompetencyAreaQuery
>;
export const listCompetencyAreas = /* GraphQL */ `query ListCompetencyAreas(
  $competencyAreaID: ID
  $filter: ModelCompetencyAreaFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCompetencyAreas(
    competencyAreaID: $competencyAreaID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      competencyAreaID
      title
      description
      gradeMatrixID
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCompetencyAreasQueryVariables,
  APITypes.ListCompetencyAreasQuery
>;
export const listCompetencyAreaByGradeMatrixID = /* GraphQL */ `query ListCompetencyAreaByGradeMatrixID(
  $gradeMatrixID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelCompetencyAreaFilterInput
  $limit: Int
  $nextToken: String
) {
  listCompetencyAreaByGradeMatrixID(
    gradeMatrixID: $gradeMatrixID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      competencyAreaID
      title
      description
      gradeMatrixID
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCompetencyAreaByGradeMatrixIDQueryVariables,
  APITypes.ListCompetencyAreaByGradeMatrixIDQuery
>;
export const getGrade = /* GraphQL */ `query GetGrade($gradeID: ID!) {
  getGrade(gradeID: $gradeID) {
    gradeID
    title
    description
    gradeMatrixID
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetGradeQueryVariables, APITypes.GetGradeQuery>;
export const listGrades = /* GraphQL */ `query ListGrades(
  $gradeID: ID
  $filter: ModelGradeFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listGrades(
    gradeID: $gradeID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      gradeID
      title
      description
      gradeMatrixID
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGradesQueryVariables,
  APITypes.ListGradesQuery
>;
export const listGradeByGradeMatrixID = /* GraphQL */ `query ListGradeByGradeMatrixID(
  $gradeMatrixID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelGradeFilterInput
  $limit: Int
  $nextToken: String
) {
  listGradeByGradeMatrixID(
    gradeMatrixID: $gradeMatrixID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      gradeID
      title
      description
      gradeMatrixID
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGradeByGradeMatrixIDQueryVariables,
  APITypes.ListGradeByGradeMatrixIDQuery
>;
export const getCompetencyItem = /* GraphQL */ `query GetCompetencyItem($competencyItemID: ID!) {
  getCompetencyItem(competencyItemID: $competencyItemID) {
    competencyItemID
    item
    gradeID
    competencyAreaID
    gradeMatrixID
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCompetencyItemQueryVariables,
  APITypes.GetCompetencyItemQuery
>;
export const listCompetencyItems = /* GraphQL */ `query ListCompetencyItems(
  $competencyItemID: ID
  $filter: ModelCompetencyItemFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCompetencyItems(
    competencyItemID: $competencyItemID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      competencyItemID
      item
      gradeID
      competencyAreaID
      gradeMatrixID
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCompetencyItemsQueryVariables,
  APITypes.ListCompetencyItemsQuery
>;
export const listCompetencyItemByOrganizationGroup = /* GraphQL */ `query ListCompetencyItemByOrganizationGroup(
  $organizationGroup: String
  $sortDirection: ModelSortDirection
  $filter: ModelCompetencyItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listCompetencyItemByOrganizationGroup(
    organizationGroup: $organizationGroup
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      competencyItemID
      item
      gradeID
      competencyAreaID
      gradeMatrixID
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCompetencyItemByOrganizationGroupQueryVariables,
  APITypes.ListCompetencyItemByOrganizationGroupQuery
>;
export const listCompetencyItemByGradeMatrixID = /* GraphQL */ `query ListCompetencyItemByGradeMatrixID(
  $gradeMatrixID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelCompetencyItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listCompetencyItemByGradeMatrixID(
    gradeMatrixID: $gradeMatrixID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      competencyItemID
      item
      gradeID
      competencyAreaID
      gradeMatrixID
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCompetencyItemByGradeMatrixIDQueryVariables,
  APITypes.ListCompetencyItemByGradeMatrixIDQuery
>;
export const listCompetencyItemByGradeID = /* GraphQL */ `query ListCompetencyItemByGradeID(
  $gradeID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelCompetencyItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listCompetencyItemByGradeID(
    gradeID: $gradeID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      competencyItemID
      item
      gradeID
      competencyAreaID
      gradeMatrixID
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCompetencyItemByGradeIDQueryVariables,
  APITypes.ListCompetencyItemByGradeIDQuery
>;
export const getSkillMap = /* GraphQL */ `query GetSkillMap($id: ID!) {
  getSkillMap(id: $id) {
    id
    name
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSkillMapQueryVariables,
  APITypes.GetSkillMapQuery
>;
export const listSkillMaps = /* GraphQL */ `query ListSkillMaps(
  $filter: ModelSkillMapFilterInput
  $limit: Int
  $nextToken: String
) {
  listSkillMaps(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSkillMapsQueryVariables,
  APITypes.ListSkillMapsQuery
>;
export const listSkillMapByOrganizationGroup = /* GraphQL */ `query ListSkillMapByOrganizationGroup(
  $organizationGroup: String
  $sortDirection: ModelSortDirection
  $filter: ModelSkillMapFilterInput
  $limit: Int
  $nextToken: String
) {
  listSkillMapByOrganizationGroup(
    organizationGroup: $organizationGroup
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSkillMapByOrganizationGroupQueryVariables,
  APITypes.ListSkillMapByOrganizationGroupQuery
>;
export const getSkillItem = /* GraphQL */ `query GetSkillItem($id: ID!) {
  getSkillItem(id: $id) {
    id
    skillMapID
    title
    description
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSkillItemQueryVariables,
  APITypes.GetSkillItemQuery
>;
export const listSkillItems = /* GraphQL */ `query ListSkillItems(
  $filter: ModelSkillItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listSkillItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      skillMapID
      title
      description
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSkillItemsQueryVariables,
  APITypes.ListSkillItemsQuery
>;
export const listSkillItemBySkillMapID = /* GraphQL */ `query ListSkillItemBySkillMapID(
  $skillMapID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelSkillItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listSkillItemBySkillMapID(
    skillMapID: $skillMapID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      skillMapID
      title
      description
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSkillItemBySkillMapIDQueryVariables,
  APITypes.ListSkillItemBySkillMapIDQuery
>;
export const listSkillItemByOrganizationGroup = /* GraphQL */ `query ListSkillItemByOrganizationGroup(
  $organizationGroup: String
  $sortDirection: ModelSortDirection
  $filter: ModelSkillItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listSkillItemByOrganizationGroup(
    organizationGroup: $organizationGroup
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      skillMapID
      title
      description
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSkillItemByOrganizationGroupQueryVariables,
  APITypes.ListSkillItemByOrganizationGroupQuery
>;
export const getLevel = /* GraphQL */ `query GetLevel($id: ID!) {
  getLevel(id: $id) {
    id
    skillMapID
    title
    description
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetLevelQueryVariables, APITypes.GetLevelQuery>;
export const listLevels = /* GraphQL */ `query ListLevels(
  $filter: ModelLevelFilterInput
  $limit: Int
  $nextToken: String
) {
  listLevels(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      skillMapID
      title
      description
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLevelsQueryVariables,
  APITypes.ListLevelsQuery
>;
export const listLevelBySkillMapID = /* GraphQL */ `query ListLevelBySkillMapID(
  $skillMapID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelLevelFilterInput
  $limit: Int
  $nextToken: String
) {
  listLevelBySkillMapID(
    skillMapID: $skillMapID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      skillMapID
      title
      description
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLevelBySkillMapIDQueryVariables,
  APITypes.ListLevelBySkillMapIDQuery
>;
export const listLevelByOrganizationGroup = /* GraphQL */ `query ListLevelByOrganizationGroup(
  $organizationGroup: String
  $sortDirection: ModelSortDirection
  $filter: ModelLevelFilterInput
  $limit: Int
  $nextToken: String
) {
  listLevelByOrganizationGroup(
    organizationGroup: $organizationGroup
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      skillMapID
      title
      description
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLevelByOrganizationGroupQueryVariables,
  APITypes.ListLevelByOrganizationGroupQuery
>;
export const getSkill = /* GraphQL */ `query GetSkill($id: ID!) {
  getSkill(id: $id) {
    id
    skillMapID
    levelID
    skillItemID
    outcome
    process
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetSkillQueryVariables, APITypes.GetSkillQuery>;
export const listSkills = /* GraphQL */ `query ListSkills(
  $filter: ModelSkillFilterInput
  $limit: Int
  $nextToken: String
) {
  listSkills(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      skillMapID
      levelID
      skillItemID
      outcome
      process
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSkillsQueryVariables,
  APITypes.ListSkillsQuery
>;
export const listSkillBySkillMapID = /* GraphQL */ `query ListSkillBySkillMapID(
  $skillMapID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelSkillFilterInput
  $limit: Int
  $nextToken: String
) {
  listSkillBySkillMapID(
    skillMapID: $skillMapID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      skillMapID
      levelID
      skillItemID
      outcome
      process
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSkillBySkillMapIDQueryVariables,
  APITypes.ListSkillBySkillMapIDQuery
>;
export const listSkillByOrganizationGroup = /* GraphQL */ `query ListSkillByOrganizationGroup(
  $organizationGroup: String
  $sortDirection: ModelSortDirection
  $filter: ModelSkillFilterInput
  $limit: Int
  $nextToken: String
) {
  listSkillByOrganizationGroup(
    organizationGroup: $organizationGroup
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      skillMapID
      levelID
      skillItemID
      outcome
      process
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSkillByOrganizationGroupQueryVariables,
  APITypes.ListSkillByOrganizationGroupQuery
>;
export const getIndividualSkill = /* GraphQL */ `query GetIndividualSkill($id: ID!) {
  getIndividualSkill(id: $id) {
    id
    skillMapID
    skillID
    acquisitionStatus
    acquisitionStartDate
    acquisitionEndDate
    userID
    organizationGroup
    createdAt
    updatedAt
    user {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetIndividualSkillQueryVariables,
  APITypes.GetIndividualSkillQuery
>;
export const listIndividualSkills = /* GraphQL */ `query ListIndividualSkills(
  $filter: ModelIndividualSkillFilterInput
  $limit: Int
  $nextToken: String
) {
  listIndividualSkills(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      skillMapID
      skillID
      acquisitionStatus
      acquisitionStartDate
      acquisitionEndDate
      userID
      organizationGroup
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListIndividualSkillsQueryVariables,
  APITypes.ListIndividualSkillsQuery
>;
export const listIndividualSkillBySkillID = /* GraphQL */ `query ListIndividualSkillBySkillID(
  $skillID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelIndividualSkillFilterInput
  $limit: Int
  $nextToken: String
) {
  listIndividualSkillBySkillID(
    skillID: $skillID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      skillMapID
      skillID
      acquisitionStatus
      acquisitionStartDate
      acquisitionEndDate
      userID
      organizationGroup
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListIndividualSkillBySkillIDQueryVariables,
  APITypes.ListIndividualSkillBySkillIDQuery
>;
export const listIndividualSkillByUserID = /* GraphQL */ `query ListIndividualSkillByUserID(
  $userID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelIndividualSkillFilterInput
  $limit: Int
  $nextToken: String
) {
  listIndividualSkillByUserID(
    userID: $userID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      skillMapID
      skillID
      acquisitionStatus
      acquisitionStartDate
      acquisitionEndDate
      userID
      organizationGroup
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListIndividualSkillByUserIDQueryVariables,
  APITypes.ListIndividualSkillByUserIDQuery
>;
export const getReviewResult = /* GraphQL */ `query GetReviewResult($reviewResultID: ID!) {
  getReviewResult(reviewResultID: $reviewResultID) {
    reviewResultID
    reviewID
    revieweeID
    finalReview
    nextGradeID
    nextBasicSalary
    salaryIncrease
    bonus
    shareStatus
    openStatus
    calculateResult {
      overallCalculateResult
      averageCalculateResult
      customReviewCalculateResults {
        customReviewID
        result
        average
        __typename
      }
      goalReviewCalculateResult
      goalReviewCalculateAverage
      competencyReviewCalculateResult
      competencyReviewCalculateAverage
      __typename
    }
    organizationGroup
    createdAt
    updatedAt
    reviewee {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    grade {
      gradeID
      title
      description
      gradeMatrixID
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    owner
    individualReviewSheetList {
      items {
        individualReviewSheetID
        name
        reviewerID
        revieweeID
        reviewOrder
        summary
        responseStage
        reviewID
        organizationGroup
        createdAt
        updatedAt
        reviewAnswerList {
          reviewItemTitle
          description
          customReviewID
          customReviewName
          goalID
          goalProgress
          finalValue
          targetValue
          goalMeasuringType
          goalResultDate
          competencyItemID
          weight
          answer
          point
          answerBasis
          __typename
        }
        calculateResult {
          overallCalculateResult
          averageCalculateResult
          customReviewCalculateResults {
            customReviewID
            result
            average
            __typename
          }
          goalReviewCalculateResult
          goalReviewCalculateAverage
          competencyReviewCalculateResult
          competencyReviewCalculateAverage
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReviewResultQueryVariables,
  APITypes.GetReviewResultQuery
>;
export const listReviewResults = /* GraphQL */ `query ListReviewResults(
  $reviewResultID: ID
  $filter: ModelReviewResultFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listReviewResults(
    reviewResultID: $reviewResultID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      reviewResultID
      reviewID
      revieweeID
      finalReview
      nextGradeID
      nextBasicSalary
      salaryIncrease
      bonus
      shareStatus
      openStatus
      calculateResult {
        overallCalculateResult
        averageCalculateResult
        customReviewCalculateResults {
          customReviewID
          result
          average
          __typename
        }
        goalReviewCalculateResult
        goalReviewCalculateAverage
        competencyReviewCalculateResult
        competencyReviewCalculateAverage
        __typename
      }
      organizationGroup
      createdAt
      updatedAt
      reviewee {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      owner
      individualReviewSheetList {
        items {
          individualReviewSheetID
          name
          reviewerID
          revieweeID
          reviewOrder
          summary
          responseStage
          reviewID
          organizationGroup
          createdAt
          updatedAt
          reviewAnswerList {
            reviewItemTitle
            description
            customReviewID
            customReviewName
            goalID
            goalProgress
            finalValue
            targetValue
            goalMeasuringType
            goalResultDate
            competencyItemID
            weight
            answer
            point
            answerBasis
            __typename
          }
          calculateResult {
            overallCalculateResult
            averageCalculateResult
            goalReviewCalculateResult
            goalReviewCalculateAverage
            competencyReviewCalculateResult
            competencyReviewCalculateAverage
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReviewResultsQueryVariables,
  APITypes.ListReviewResultsQuery
>;
export const listReviewResultByReviewID = /* GraphQL */ `query ListReviewResultByReviewID(
  $reviewID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelReviewResultFilterInput
  $limit: Int
  $nextToken: String
) {
  listReviewResultByReviewID(
    reviewID: $reviewID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      reviewResultID
      reviewID
      revieweeID
      finalReview
      nextGradeID
      nextBasicSalary
      salaryIncrease
      bonus
      shareStatus
      openStatus
      calculateResult {
        overallCalculateResult
        averageCalculateResult
        customReviewCalculateResults {
          customReviewID
          result
          average
          __typename
        }
        goalReviewCalculateResult
        goalReviewCalculateAverage
        competencyReviewCalculateResult
        competencyReviewCalculateAverage
        __typename
      }
      organizationGroup
      createdAt
      updatedAt
      reviewee {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      owner
      individualReviewSheetList {
        items {
          individualReviewSheetID
          name
          reviewerID
          revieweeID
          reviewOrder
          summary
          responseStage
          reviewID
          organizationGroup
          createdAt
          updatedAt
          reviewAnswerList {
            reviewItemTitle
            description
            customReviewID
            customReviewName
            goalID
            goalProgress
            finalValue
            targetValue
            goalMeasuringType
            goalResultDate
            competencyItemID
            weight
            answer
            point
            answerBasis
            __typename
          }
          calculateResult {
            overallCalculateResult
            averageCalculateResult
            goalReviewCalculateResult
            goalReviewCalculateAverage
            competencyReviewCalculateResult
            competencyReviewCalculateAverage
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReviewResultByReviewIDQueryVariables,
  APITypes.ListReviewResultByReviewIDQuery
>;
export const listReviewResultByReviewAndReviewee = /* GraphQL */ `query ListReviewResultByReviewAndReviewee(
  $reviewID: ID
  $revieweeID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReviewResultFilterInput
  $limit: Int
  $nextToken: String
) {
  listReviewResultByReviewAndReviewee(
    reviewID: $reviewID
    revieweeID: $revieweeID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      reviewResultID
      reviewID
      revieweeID
      finalReview
      nextGradeID
      nextBasicSalary
      salaryIncrease
      bonus
      shareStatus
      openStatus
      calculateResult {
        overallCalculateResult
        averageCalculateResult
        customReviewCalculateResults {
          customReviewID
          result
          average
          __typename
        }
        goalReviewCalculateResult
        goalReviewCalculateAverage
        competencyReviewCalculateResult
        competencyReviewCalculateAverage
        __typename
      }
      organizationGroup
      createdAt
      updatedAt
      reviewee {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      owner
      individualReviewSheetList {
        items {
          individualReviewSheetID
          name
          reviewerID
          revieweeID
          reviewOrder
          summary
          responseStage
          reviewID
          organizationGroup
          createdAt
          updatedAt
          reviewAnswerList {
            reviewItemTitle
            description
            customReviewID
            customReviewName
            goalID
            goalProgress
            finalValue
            targetValue
            goalMeasuringType
            goalResultDate
            competencyItemID
            weight
            answer
            point
            answerBasis
            __typename
          }
          calculateResult {
            overallCalculateResult
            averageCalculateResult
            goalReviewCalculateResult
            goalReviewCalculateAverage
            competencyReviewCalculateResult
            competencyReviewCalculateAverage
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReviewResultByReviewAndRevieweeQueryVariables,
  APITypes.ListReviewResultByReviewAndRevieweeQuery
>;
export const getIndividualReviewSheet = /* GraphQL */ `query GetIndividualReviewSheet($individualReviewSheetID: ID!) {
  getIndividualReviewSheet(individualReviewSheetID: $individualReviewSheetID) {
    individualReviewSheetID
    name
    reviewerID
    revieweeID
    reviewOrder
    summary
    responseStage
    reviewID
    organizationGroup
    createdAt
    updatedAt
    reviewAnswerList {
      reviewItemTitle
      description
      customReviewID
      customReviewName
      goalID
      goalProgress
      finalValue
      targetValue
      goalMeasuringType
      goalResultDate
      competencyItemID
      weight
      answer
      point
      answerBasis
      __typename
    }
    calculateResult {
      overallCalculateResult
      averageCalculateResult
      customReviewCalculateResults {
        customReviewID
        result
        average
        __typename
      }
      goalReviewCalculateResult
      goalReviewCalculateAverage
      competencyReviewCalculateResult
      competencyReviewCalculateAverage
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetIndividualReviewSheetQueryVariables,
  APITypes.GetIndividualReviewSheetQuery
>;
export const listIndividualReviewSheets = /* GraphQL */ `query ListIndividualReviewSheets(
  $individualReviewSheetID: ID
  $filter: ModelIndividualReviewSheetFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listIndividualReviewSheets(
    individualReviewSheetID: $individualReviewSheetID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      individualReviewSheetID
      name
      reviewerID
      revieweeID
      reviewOrder
      summary
      responseStage
      reviewID
      organizationGroup
      createdAt
      updatedAt
      reviewAnswerList {
        reviewItemTitle
        description
        customReviewID
        customReviewName
        goalID
        goalProgress
        finalValue
        targetValue
        goalMeasuringType
        goalResultDate
        competencyItemID
        weight
        answer
        point
        answerBasis
        __typename
      }
      calculateResult {
        overallCalculateResult
        averageCalculateResult
        customReviewCalculateResults {
          customReviewID
          result
          average
          __typename
        }
        goalReviewCalculateResult
        goalReviewCalculateAverage
        competencyReviewCalculateResult
        competencyReviewCalculateAverage
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListIndividualReviewSheetsQueryVariables,
  APITypes.ListIndividualReviewSheetsQuery
>;
export const listIndividualReviewSheetByReviewID = /* GraphQL */ `query ListIndividualReviewSheetByReviewID(
  $reviewID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelIndividualReviewSheetFilterInput
  $limit: Int
  $nextToken: String
) {
  listIndividualReviewSheetByReviewID(
    reviewID: $reviewID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      individualReviewSheetID
      name
      reviewerID
      revieweeID
      reviewOrder
      summary
      responseStage
      reviewID
      organizationGroup
      createdAt
      updatedAt
      reviewAnswerList {
        reviewItemTitle
        description
        customReviewID
        customReviewName
        goalID
        goalProgress
        finalValue
        targetValue
        goalMeasuringType
        goalResultDate
        competencyItemID
        weight
        answer
        point
        answerBasis
        __typename
      }
      calculateResult {
        overallCalculateResult
        averageCalculateResult
        customReviewCalculateResults {
          customReviewID
          result
          average
          __typename
        }
        goalReviewCalculateResult
        goalReviewCalculateAverage
        competencyReviewCalculateResult
        competencyReviewCalculateAverage
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListIndividualReviewSheetByReviewIDQueryVariables,
  APITypes.ListIndividualReviewSheetByReviewIDQuery
>;
export const listIndividualReviewSheetByReviewerID = /* GraphQL */ `query ListIndividualReviewSheetByReviewerID(
  $reviewerID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelIndividualReviewSheetFilterInput
  $limit: Int
  $nextToken: String
) {
  listIndividualReviewSheetByReviewerID(
    reviewerID: $reviewerID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      individualReviewSheetID
      name
      reviewerID
      revieweeID
      reviewOrder
      summary
      responseStage
      reviewID
      organizationGroup
      createdAt
      updatedAt
      reviewAnswerList {
        reviewItemTitle
        description
        customReviewID
        customReviewName
        goalID
        goalProgress
        finalValue
        targetValue
        goalMeasuringType
        goalResultDate
        competencyItemID
        weight
        answer
        point
        answerBasis
        __typename
      }
      calculateResult {
        overallCalculateResult
        averageCalculateResult
        customReviewCalculateResults {
          customReviewID
          result
          average
          __typename
        }
        goalReviewCalculateResult
        goalReviewCalculateAverage
        competencyReviewCalculateResult
        competencyReviewCalculateAverage
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListIndividualReviewSheetByReviewerIDQueryVariables,
  APITypes.ListIndividualReviewSheetByReviewerIDQuery
>;
export const listIndividualReviewSheetByReviewAndReviewee = /* GraphQL */ `query ListIndividualReviewSheetByReviewAndReviewee(
  $reviewID: ID
  $revieweeID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelIndividualReviewSheetFilterInput
  $limit: Int
  $nextToken: String
) {
  listIndividualReviewSheetByReviewAndReviewee(
    reviewID: $reviewID
    revieweeID: $revieweeID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      individualReviewSheetID
      name
      reviewerID
      revieweeID
      reviewOrder
      summary
      responseStage
      reviewID
      organizationGroup
      createdAt
      updatedAt
      reviewAnswerList {
        reviewItemTitle
        description
        customReviewID
        customReviewName
        goalID
        goalProgress
        finalValue
        targetValue
        goalMeasuringType
        goalResultDate
        competencyItemID
        weight
        answer
        point
        answerBasis
        __typename
      }
      calculateResult {
        overallCalculateResult
        averageCalculateResult
        customReviewCalculateResults {
          customReviewID
          result
          average
          __typename
        }
        goalReviewCalculateResult
        goalReviewCalculateAverage
        competencyReviewCalculateResult
        competencyReviewCalculateAverage
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListIndividualReviewSheetByReviewAndRevieweeQueryVariables,
  APITypes.ListIndividualReviewSheetByReviewAndRevieweeQuery
>;
export const listReviewSheetByReviewAndReviewer = /* GraphQL */ `query ListReviewSheetByReviewAndReviewer(
  $reviewID: ID
  $reviewerID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelIndividualReviewSheetFilterInput
  $limit: Int
  $nextToken: String
) {
  listReviewSheetByReviewAndReviewer(
    reviewID: $reviewID
    reviewerID: $reviewerID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      individualReviewSheetID
      name
      reviewerID
      revieweeID
      reviewOrder
      summary
      responseStage
      reviewID
      organizationGroup
      createdAt
      updatedAt
      reviewAnswerList {
        reviewItemTitle
        description
        customReviewID
        customReviewName
        goalID
        goalProgress
        finalValue
        targetValue
        goalMeasuringType
        goalResultDate
        competencyItemID
        weight
        answer
        point
        answerBasis
        __typename
      }
      calculateResult {
        overallCalculateResult
        averageCalculateResult
        customReviewCalculateResults {
          customReviewID
          result
          average
          __typename
        }
        goalReviewCalculateResult
        goalReviewCalculateAverage
        competencyReviewCalculateResult
        competencyReviewCalculateAverage
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReviewSheetByReviewAndReviewerQueryVariables,
  APITypes.ListReviewSheetByReviewAndReviewerQuery
>;
export const listReviewSheetByReviewAndReviewerAndReviewee = /* GraphQL */ `query ListReviewSheetByReviewAndReviewerAndReviewee(
  $reviewID: ID
  $reviewerIDRevieweeID: ModelIndividualReviewSheetListReviewSheetByReviewAndReviewerAndRevieweeCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelIndividualReviewSheetFilterInput
  $limit: Int
  $nextToken: String
) {
  listReviewSheetByReviewAndReviewerAndReviewee(
    reviewID: $reviewID
    reviewerIDRevieweeID: $reviewerIDRevieweeID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      individualReviewSheetID
      name
      reviewerID
      revieweeID
      reviewOrder
      summary
      responseStage
      reviewID
      organizationGroup
      createdAt
      updatedAt
      reviewAnswerList {
        reviewItemTitle
        description
        customReviewID
        customReviewName
        goalID
        goalProgress
        finalValue
        targetValue
        goalMeasuringType
        goalResultDate
        competencyItemID
        weight
        answer
        point
        answerBasis
        __typename
      }
      calculateResult {
        overallCalculateResult
        averageCalculateResult
        customReviewCalculateResults {
          customReviewID
          result
          average
          __typename
        }
        goalReviewCalculateResult
        goalReviewCalculateAverage
        competencyReviewCalculateResult
        competencyReviewCalculateAverage
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReviewSheetByReviewAndReviewerAndRevieweeQueryVariables,
  APITypes.ListReviewSheetByReviewAndReviewerAndRevieweeQuery
>;
export const getReview = /* GraphQL */ `query GetReview($reviewID: ID!) {
  getReview(reviewID: $reviewID) {
    reviewID
    name
    revieweeSetting
    revieweeReviewerSettings {
      revieweeID
      gradeID
      firstReviewerIDs
      secondReviewerIDs
      __typename
    }
    reviewTemplateID
    reviewPeriod {
      startDate
      endDate
      __typename
    }
    targetGoalPeriod {
      startDate
      endDate
      __typename
    }
    remindNotificationDate
    isSummary
    isCalculate
    reviewStage
    organizationGroup
    createdAt
    updatedAt
    reviewResultList {
      items {
        reviewResultID
        reviewID
        revieweeID
        finalReview
        nextGradeID
        nextBasicSalary
        salaryIncrease
        bonus
        shareStatus
        openStatus
        calculateResult {
          overallCalculateResult
          averageCalculateResult
          customReviewCalculateResults {
            customReviewID
            result
            average
            __typename
          }
          goalReviewCalculateResult
          goalReviewCalculateAverage
          competencyReviewCalculateResult
          competencyReviewCalculateAverage
          __typename
        }
        organizationGroup
        createdAt
        updatedAt
        reviewee {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        owner
        individualReviewSheetList {
          items {
            individualReviewSheetID
            name
            reviewerID
            revieweeID
            reviewOrder
            summary
            responseStage
            reviewID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    individualReviewSheetList {
      items {
        individualReviewSheetID
        name
        reviewerID
        revieweeID
        reviewOrder
        summary
        responseStage
        reviewID
        organizationGroup
        createdAt
        updatedAt
        reviewAnswerList {
          reviewItemTitle
          description
          customReviewID
          customReviewName
          goalID
          goalProgress
          finalValue
          targetValue
          goalMeasuringType
          goalResultDate
          competencyItemID
          weight
          answer
          point
          answerBasis
          __typename
        }
        calculateResult {
          overallCalculateResult
          averageCalculateResult
          customReviewCalculateResults {
            customReviewID
            result
            average
            __typename
          }
          goalReviewCalculateResult
          goalReviewCalculateAverage
          competencyReviewCalculateResult
          competencyReviewCalculateAverage
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reviewTemplate {
      reviewTemplateID
      name
      description
      reviewMethod {
        isSelfReview
        isFirstReview
        isSecondReview
        isFinalReview
        __typename
      }
      reviewSettings {
        customReviewSettings {
          customReviewID
          weight
          __typename
        }
        goalReviewSetting {
          isReviewInclude
          question
          description
          goalRange
          goalStatus
          answerType
          options {
            item
            pointAllocation
            __typename
          }
          numberRange {
            lowerLimit
            upperLimit
            __typename
          }
          isAnswerBasis
          isAnswerBasisEntryOptional
          weight
          __typename
        }
        competencyReviewSetting {
          isReviewInclude
          question
          description
          answerType
          options {
            item
            pointAllocation
            __typename
          }
          numberRange {
            lowerLimit
            upperLimit
            __typename
          }
          isAnswerBasis
          isAnswerBasisEntryOptional
          weight
          __typename
        }
        isWeight
        __typename
      }
      calculable
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetReviewQueryVariables, APITypes.GetReviewQuery>;
export const listReviews = /* GraphQL */ `query ListReviews(
  $reviewID: ID
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listReviews(
    reviewID: $reviewID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      reviewID
      name
      revieweeSetting
      revieweeReviewerSettings {
        revieweeID
        gradeID
        firstReviewerIDs
        secondReviewerIDs
        __typename
      }
      reviewTemplateID
      reviewPeriod {
        startDate
        endDate
        __typename
      }
      targetGoalPeriod {
        startDate
        endDate
        __typename
      }
      remindNotificationDate
      isSummary
      isCalculate
      reviewStage
      organizationGroup
      createdAt
      updatedAt
      reviewResultList {
        items {
          reviewResultID
          reviewID
          revieweeID
          finalReview
          nextGradeID
          nextBasicSalary
          salaryIncrease
          bonus
          shareStatus
          openStatus
          calculateResult {
            overallCalculateResult
            averageCalculateResult
            goalReviewCalculateResult
            goalReviewCalculateAverage
            competencyReviewCalculateResult
            competencyReviewCalculateAverage
            __typename
          }
          organizationGroup
          createdAt
          updatedAt
          reviewee {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          owner
          individualReviewSheetList {
            nextToken
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      individualReviewSheetList {
        items {
          individualReviewSheetID
          name
          reviewerID
          revieweeID
          reviewOrder
          summary
          responseStage
          reviewID
          organizationGroup
          createdAt
          updatedAt
          reviewAnswerList {
            reviewItemTitle
            description
            customReviewID
            customReviewName
            goalID
            goalProgress
            finalValue
            targetValue
            goalMeasuringType
            goalResultDate
            competencyItemID
            weight
            answer
            point
            answerBasis
            __typename
          }
          calculateResult {
            overallCalculateResult
            averageCalculateResult
            goalReviewCalculateResult
            goalReviewCalculateAverage
            competencyReviewCalculateResult
            competencyReviewCalculateAverage
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      reviewTemplate {
        reviewTemplateID
        name
        description
        reviewMethod {
          isSelfReview
          isFirstReview
          isSecondReview
          isFinalReview
          __typename
        }
        reviewSettings {
          customReviewSettings {
            customReviewID
            weight
            __typename
          }
          goalReviewSetting {
            isReviewInclude
            question
            description
            goalRange
            goalStatus
            answerType
            isAnswerBasis
            isAnswerBasisEntryOptional
            weight
            __typename
          }
          competencyReviewSetting {
            isReviewInclude
            question
            description
            answerType
            isAnswerBasis
            isAnswerBasisEntryOptional
            weight
            __typename
          }
          isWeight
          __typename
        }
        calculable
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReviewsQueryVariables,
  APITypes.ListReviewsQuery
>;
export const listReviewByOrganizationGroup = /* GraphQL */ `query ListReviewByOrganizationGroup(
  $organizationGroup: String
  $sortDirection: ModelSortDirection
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  listReviewByOrganizationGroup(
    organizationGroup: $organizationGroup
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      reviewID
      name
      revieweeSetting
      revieweeReviewerSettings {
        revieweeID
        gradeID
        firstReviewerIDs
        secondReviewerIDs
        __typename
      }
      reviewTemplateID
      reviewPeriod {
        startDate
        endDate
        __typename
      }
      targetGoalPeriod {
        startDate
        endDate
        __typename
      }
      remindNotificationDate
      isSummary
      isCalculate
      reviewStage
      organizationGroup
      createdAt
      updatedAt
      reviewResultList {
        items {
          reviewResultID
          reviewID
          revieweeID
          finalReview
          nextGradeID
          nextBasicSalary
          salaryIncrease
          bonus
          shareStatus
          openStatus
          calculateResult {
            overallCalculateResult
            averageCalculateResult
            goalReviewCalculateResult
            goalReviewCalculateAverage
            competencyReviewCalculateResult
            competencyReviewCalculateAverage
            __typename
          }
          organizationGroup
          createdAt
          updatedAt
          reviewee {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          owner
          individualReviewSheetList {
            nextToken
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      individualReviewSheetList {
        items {
          individualReviewSheetID
          name
          reviewerID
          revieweeID
          reviewOrder
          summary
          responseStage
          reviewID
          organizationGroup
          createdAt
          updatedAt
          reviewAnswerList {
            reviewItemTitle
            description
            customReviewID
            customReviewName
            goalID
            goalProgress
            finalValue
            targetValue
            goalMeasuringType
            goalResultDate
            competencyItemID
            weight
            answer
            point
            answerBasis
            __typename
          }
          calculateResult {
            overallCalculateResult
            averageCalculateResult
            goalReviewCalculateResult
            goalReviewCalculateAverage
            competencyReviewCalculateResult
            competencyReviewCalculateAverage
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      reviewTemplate {
        reviewTemplateID
        name
        description
        reviewMethod {
          isSelfReview
          isFirstReview
          isSecondReview
          isFinalReview
          __typename
        }
        reviewSettings {
          customReviewSettings {
            customReviewID
            weight
            __typename
          }
          goalReviewSetting {
            isReviewInclude
            question
            description
            goalRange
            goalStatus
            answerType
            isAnswerBasis
            isAnswerBasisEntryOptional
            weight
            __typename
          }
          competencyReviewSetting {
            isReviewInclude
            question
            description
            answerType
            isAnswerBasis
            isAnswerBasisEntryOptional
            weight
            __typename
          }
          isWeight
          __typename
        }
        calculable
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReviewByOrganizationGroupQueryVariables,
  APITypes.ListReviewByOrganizationGroupQuery
>;
export const getNewReviewTemplate = /* GraphQL */ `query GetNewReviewTemplate($reviewTemplateID: ID!) {
  getNewReviewTemplate(reviewTemplateID: $reviewTemplateID) {
    reviewTemplateID
    name
    description
    reviewMethod {
      isSelfReview
      isFirstReview
      isSecondReview
      isFinalReview
      __typename
    }
    reviewSettings {
      customReviewSettings {
        customReviewID
        weight
        __typename
      }
      goalReviewSetting {
        isReviewInclude
        question
        description
        goalRange
        goalStatus
        answerType
        options {
          item
          pointAllocation
          __typename
        }
        numberRange {
          lowerLimit
          upperLimit
          __typename
        }
        isAnswerBasis
        isAnswerBasisEntryOptional
        weight
        __typename
      }
      competencyReviewSetting {
        isReviewInclude
        question
        description
        answerType
        options {
          item
          pointAllocation
          __typename
        }
        numberRange {
          lowerLimit
          upperLimit
          __typename
        }
        isAnswerBasis
        isAnswerBasisEntryOptional
        weight
        __typename
      }
      isWeight
      __typename
    }
    calculable
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNewReviewTemplateQueryVariables,
  APITypes.GetNewReviewTemplateQuery
>;
export const listNewReviewTemplates = /* GraphQL */ `query ListNewReviewTemplates(
  $reviewTemplateID: ID
  $filter: ModelNewReviewTemplateFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listNewReviewTemplates(
    reviewTemplateID: $reviewTemplateID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      reviewTemplateID
      name
      description
      reviewMethod {
        isSelfReview
        isFirstReview
        isSecondReview
        isFinalReview
        __typename
      }
      reviewSettings {
        customReviewSettings {
          customReviewID
          weight
          __typename
        }
        goalReviewSetting {
          isReviewInclude
          question
          description
          goalRange
          goalStatus
          answerType
          options {
            item
            pointAllocation
            __typename
          }
          numberRange {
            lowerLimit
            upperLimit
            __typename
          }
          isAnswerBasis
          isAnswerBasisEntryOptional
          weight
          __typename
        }
        competencyReviewSetting {
          isReviewInclude
          question
          description
          answerType
          options {
            item
            pointAllocation
            __typename
          }
          numberRange {
            lowerLimit
            upperLimit
            __typename
          }
          isAnswerBasis
          isAnswerBasisEntryOptional
          weight
          __typename
        }
        isWeight
        __typename
      }
      calculable
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNewReviewTemplatesQueryVariables,
  APITypes.ListNewReviewTemplatesQuery
>;
export const listReviewTemplateByOrganizationGroup = /* GraphQL */ `query ListReviewTemplateByOrganizationGroup(
  $organizationGroup: String
  $sortDirection: ModelSortDirection
  $filter: ModelNewReviewTemplateFilterInput
  $limit: Int
  $nextToken: String
) {
  listReviewTemplateByOrganizationGroup(
    organizationGroup: $organizationGroup
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      reviewTemplateID
      name
      description
      reviewMethod {
        isSelfReview
        isFirstReview
        isSecondReview
        isFinalReview
        __typename
      }
      reviewSettings {
        customReviewSettings {
          customReviewID
          weight
          __typename
        }
        goalReviewSetting {
          isReviewInclude
          question
          description
          goalRange
          goalStatus
          answerType
          options {
            item
            pointAllocation
            __typename
          }
          numberRange {
            lowerLimit
            upperLimit
            __typename
          }
          isAnswerBasis
          isAnswerBasisEntryOptional
          weight
          __typename
        }
        competencyReviewSetting {
          isReviewInclude
          question
          description
          answerType
          options {
            item
            pointAllocation
            __typename
          }
          numberRange {
            lowerLimit
            upperLimit
            __typename
          }
          isAnswerBasis
          isAnswerBasisEntryOptional
          weight
          __typename
        }
        isWeight
        __typename
      }
      calculable
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReviewTemplateByOrganizationGroupQueryVariables,
  APITypes.ListReviewTemplateByOrganizationGroupQuery
>;
export const getCustomReview = /* GraphQL */ `query GetCustomReview($customReviewID: ID!) {
  getCustomReview(customReviewID: $customReviewID) {
    customReviewID
    name
    isWeight
    customReviewItemList {
      name
      weight
      __typename
    }
    question
    description
    answerType
    organizationGroup
    options {
      item
      pointAllocation
      __typename
    }
    numberRange {
      lowerLimit
      upperLimit
      __typename
    }
    isAnswerBasis
    isAnswerBasisEntryOptional
    calculable
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomReviewQueryVariables,
  APITypes.GetCustomReviewQuery
>;
export const listCustomReviews = /* GraphQL */ `query ListCustomReviews(
  $customReviewID: ID
  $filter: ModelCustomReviewFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCustomReviews(
    customReviewID: $customReviewID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      customReviewID
      name
      isWeight
      customReviewItemList {
        name
        weight
        __typename
      }
      question
      description
      answerType
      organizationGroup
      options {
        item
        pointAllocation
        __typename
      }
      numberRange {
        lowerLimit
        upperLimit
        __typename
      }
      isAnswerBasis
      isAnswerBasisEntryOptional
      calculable
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCustomReviewsQueryVariables,
  APITypes.ListCustomReviewsQuery
>;
export const listCustomReviewByOrganizationGroup = /* GraphQL */ `query ListCustomReviewByOrganizationGroup(
  $organizationGroup: String
  $sortDirection: ModelSortDirection
  $filter: ModelCustomReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  listCustomReviewByOrganizationGroup(
    organizationGroup: $organizationGroup
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      customReviewID
      name
      isWeight
      customReviewItemList {
        name
        weight
        __typename
      }
      question
      description
      answerType
      organizationGroup
      options {
        item
        pointAllocation
        __typename
      }
      numberRange {
        lowerLimit
        upperLimit
        __typename
      }
      isAnswerBasis
      isAnswerBasisEntryOptional
      calculable
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCustomReviewByOrganizationGroupQueryVariables,
  APITypes.ListCustomReviewByOrganizationGroupQuery
>;
