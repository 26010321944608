import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

interface GradientsPaletteOptions {
  primary: string;
  info: string;
  success: string;
  warning: string;
  error: string;
}

interface ChartPaletteOptions {
  violet: string[];
  blue: string[];
  green: string[];
  yellow: string[];
  red: string[];
}

interface SurfaceOptions {
  primary: string;
  secondary: string;
  tertiary: string;
  sidebarLight: string;
  sidebarDark: string;
  sidebarDarker: string;
}

interface BorderOptions {
  primary: string;
  secondary: string;
  tertiary: string;
}

interface IconOptions {
  primary: string;
  secondary: string;
  tertiary: string;
  quaternary: string;
}

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
    secondary: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
  interface Palette {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
    surface: SurfaceOptions;
    border: BorderOptions;
    icon: IconOptions;
  }
  interface PaletteOptions {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
    surface: SurfaceOptions;
    border: BorderOptions;
    icon: IconOptions;
  }
  interface TypeText {
    primary: string;
    secondary: string;
    tertiary: string;
    contrast: string;
    info: string;
    error: string;
    disabled: string;
    link: string;
  }
}

declare module '@mui/material' {
  interface Color {
    0: string;
    250: string;
    450: string;
    500_8: string;
    500_12: string;
    500_16: string;
    500_24: string;
    500_32: string;
    500_48: string;
    500_56: string;
    500_80: string;
  }
}

// SETUP COLORS
const PRIMARY = {
  lighter: '#F5E4E3', // ボタンのボーダーなどに利用
  light: '#FF9991',
  main: '#FF560F',
  dark: '#D73E00',
  darker: '#AF3100',
};
const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#8271fa',
  main: '#2B2E43',
  dark: '#001d94',
  darker: '#001d94',
};
const INFO = {
  lighter: '#D0F2FF',
  light: '#DDEBFC',
  main: '#5298F8',
  dark: '#0C53B7',
  darker: '#04297A',
};
const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#D8F3DF',
  main: '#2BAA4C',
  dark: '#229A16',
  darker: '#08660D',
};
const WARNING = {
  lighter: '#F9F6DA',
  light: '#F9F6DA',
  main: '#ECDC48',
  dark: '#CFBF28',
  darker: '#7A4F01',
};
const ERROR = {
  lighter: '#FCD9D7',
  light: '#FCD9D7',
  main: '#D90F00',
  dark: '#B30D00',
  darker: '#7A0C2E',
};

const GREY = {
  0: '#FFFFFF',
  100: '#FCFCFD',
  200: '#F2F2F2',
  250: '#F0F0F5',
  300: '#DFE3E8',
  400: '#C4CDD5',
  450: '#79747E',
  500: '#86818A',
  600: '#637381',
  700: '#52587B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const SURFACE = {
  primary: '#FAFDFF',
  secondary: '#EEEEF2',
  tertiary: '#E0E0E7',
  sidebarLight: '#FCFCFD',
  sidebarDark: '#2B2E43',
  sidebarDarker: '#454C66',
};

const BORDER = {
  primary: '#C6C5D1',
  secondary: '#4D4B57',
  tertiary: '#878594',
};

const ICON = {
  primary: '#878594',
  secondary: '#2B2E43',
  tertiary: '#B0AFBD',
  quaternary: '#FAFDFF',
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#FAFDFF' },
  secondary: { ...SECONDARY, contrastText: '#FAFDFF' },
  info: { ...INFO, contrastText: '#FAFDFF' },
  success: { ...SUCCESS, contrastText: '#FAFDFF' },
  warning: { ...WARNING, contrastText: '#FAFDFF' },
  error: { ...ERROR, contrastText: '#FAFDFF' },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[600],
  surface: { ...SURFACE },
  border: { ...BORDER },
  icon: { ...ICON },
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON,
    mode: 'light',
    text: {
      primary: '#28272E',
      secondary: '#4D4B57',
      tertiary: '#878594',
      disabled: '#B0AFBD',
      info: INFO.main,
      error: ERROR.main,
      link: INFO.main,
      contrast: '#FAFDFF',
    },
    background: {
      paper: '#FAFDFF',
      default: '#FAFDFF',
      neutral: '#FAFDFF',
      secondary: '#EEEEF2',
    },
    action: { active: GREY[600], ...COMMON.action },
    surface: {
      ...SURFACE,
    },
    border: BORDER,
    icon: ICON,
  },
  dark: {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: '#FAFDFF',
      secondary: GREY[500],
      tertiary: GREY[700],
      disabled: GREY[600],
      info: INFO.main,
      error: ERROR.main,
      link: '#52587B',
      contrast: '#F2F2F1',
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: GREY[500_16],
      secondary: '#EEEEF2',
    },
    action: { active: GREY[500], ...COMMON.action },
  },
} as const;

export default palette;
